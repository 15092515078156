import { Box, Container, Heading, Flex } from 'theme-ui';
import ReactMarkdown from 'react-markdown'
import MarkdownContent from './markdown'

// See https://remarkjs.github.io/react-markdown/

const WebsiteTermsOfUse = () => {
  return (
    <Box id="website-terms-of-use" as="section" variant="section.page">
      <Container>
        <Box sx={styles.contentWrapper}>
          <Box sx={styles.content}>
            <Heading sx={styles.title}>
              Website Terms of Use
            </Heading>
            <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Version 1.0</p>
            <p style={{ textAlign: 'center' }}>Last Updated: September 28, 2021</p>
            <Flex>
              <div style={{ width: '100%', margin: '0 auto', marginTop: 0, marginBottom: 50 }}>
                <ReactMarkdown children={MarkdownContent} />
              </div>
            </Flex>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default WebsiteTermsOfUse;

const styles = {
  contentWrapper: {
    display: [null, null, null, 'flex', 'grid'],
    gridTemplateColumns: '1',
    alignItems: 'flex-start',
    justifyContent: 'center',
    minHeight: [null, null, null, null, null, '100vh'],
    pt: [100, null, null, 120, 130, 120, 120],
  },
  content: {
    maxWidth: [507, null, null, 800, 1000],
  },
  text: {
    fontSize: ['14px', '14px', '14px', '16px', '16px', '22px'],
    lineHeight: [1.85, 1.85, 1.85, 1.85, 1.85, 2.33],
    color: 'textSecondary',
    mt: ['30px', null, null, null, '42px', '40px', '60px'],
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: ['30px', null, null, null, '42px', '40px', '60px'],
    lineHeight: 1.33,
    letterSpacing: '-1px',
    color: 'textSecondary',
  },
};
