import { Box, Container, Link, Flex } from 'theme-ui';
import ReactMarkdown from 'react-markdown'
import SectionHeader from '../../components/section-heading';
import theme from '../../theme';

const MarkdownContent =
    `
## The market leader...

- Credify is the new mortgage marketplace where the best deals come to you effortlessly from multiple licensed mortgage brokers and lenders in your State, who compete to offer you the best mortgage package.
- Credify covers all FICO scores, loan types and property types.
- Mortgage brokers and lenders don’t see your personal identifying information so you don’t get unwanted phone calls or emails and will never suffer from unconscious bias in lending decisions..
- It is also the market leader for getting fast pre-approvals for your mortgage.

## Plug in your data to turbocharge your application!
- Get your maximum borrowing capacity from multiple brokers and lenders so you know how much home you can buy. It might be higher than you think!
- By using Credify's financial data integrations, Credify can pull your data from various sources (like Sofi), meaning you don't have to spend time producing documents from banks or other sources. This can make all the difference when you need to act quickly in hot real estate markets.
- Using Credify's financial integrations also ensures the accuracy of your data so you avoid potentially catastrophic processing delays when securing your new dream home, refinance or cash-out.
- If for any reason, your application is rejected, you don’t need to go through the entire process again. Credify has saved all your data securely so re-applying can be done in minutes.

## Increase your chances of home buying success with Credify
- The first offer you receive generates a pre-approval letter from Credify that you can take to your real estate agent. This shows home sellers you are serious and ready to act, dramatically increasing your chances of securing your new home.
- Once pre-approved, you can begin the process to become a cash buyer. Cash buyers enjoy an average 3% to 4% cash discount, 4x greater home offer acceptance rate and completion times as fast as 72 hours.
- Credify processing is fast too. Brokers and lenders can plug directly into Credify’s API to process loan applications fast!
`

const BorrowerAboutCredify = (props) => {
    let boxStyle = props.box === 'alternatingBox' ? theme.styles.alternatingBox : theme.styles.sectionBox;
    return (
        <Box id="about-credify" as="section" variant="section.page" sx={boxStyle}>
            <Container>
                <Box sx={styles.contentWrapper}>
                    <Box sx={styles.content}>
                        <SectionHeader
                            title="Tell me more about Credify!"
                            description="What is it? How does it work?"
                            sx={{ margin: '0 auto', textAlign: 'center' }}
                        />

                        <Flex sx={styles.markdown}>
                            <div sx={styles.markdown}>
                                <ReactMarkdown sx={styles.markdown} children={MarkdownContent} />
                                <Link
                                    className='borrowerCta'
                                    data-lum="action"
                                    variant="primary"
                                    sx={styles.button}
                                    href={`https://approve${process.env.REACT_APP_NODE_ENV === 'staging' ? '-staging4nf' : ''}.getcredify.com`}>
                                    Get Turbo Approved Now
                                </Link>
                            </div>
                        </Flex>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default BorrowerAboutCredify;

const styles = {
    markdown: {
        textAlign: ["justify", null, null, "left"],
        maxWidth: [507, null, null, 700, 700],
        margin: '0 auto',
        h2: {
            textAlign: 'left',
            lineHeight: 1.3,
            fontSize: '20px',
            fontWeight: '500'
        }
    },
    contentWrapper: {
        display: [null, null, null, 'flex', 'grid'],
        gridTemplateColumns: '1',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    content: {
        maxWidth: [null, null, null, 800, 1000],
    },
    title: {
        fontWeight: 700,
        fontSize: 22,
        letterSpacing: '-0.55px',
    },
    subtitle: {
        mt: '6px',
    },
    image: {
        mt: 20,
        height: 200
    },
    priceTable: {
        border: `1px solid #F3F4F5`,
        textAlign: 'center',
        borderRadius: 10,
        position: 'relative',
        padding: ['20px 20px', null, null, null, null],
        '&.recommended': {
            backgroundColor: '#fff',
            borderColor: 'primary',
            borderWidth: '2.5px',
            color: 'text',
            '@media only screen and (max-width: 767px)': {
                pt: 65,
            },
            footer: {
                backgroundColor: '#F0F0F5',
                borderRadius: '0 0 10px 10px',
                '.price-label': {
                    color: 'text',
                },
                '.price-value': {
                    color: 'primary',
                },
            },
        },
    },
    button: {
        display: ['grid'],
        justifyContent: 'center',
        width: ['100%'],
        maxWidth: '400px',
        margin: '30px auto 0px',
        borderRadius: 5,
        color: "#FFF",
        backgroundColor: "primary",
        lineHeight: '50px',
        height: '50px',
        fontSize: ['15px', '20px', '20px', '15px', '18px', '20px'],
        fontWeight: '500',
        textDecoration: "none",
        ":hover": {
            backgroundColor: "dark"
        }
    },
};
