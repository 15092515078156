import * as React from 'react';
import { Box, Container } from 'theme-ui';
import theme from '../../theme';
import SectionHeader from '../../components/section-heading';
import Table from '../../sections/borrowers-2/table'

const ComparisonTable = (props) => {
let boxStyle = props.box === 'alternatingBox' ? theme.styles.alternatingBox : theme.styles.sectionBox;
    return (
        <Box id="comparison-table" as="section" variant="section.page" sx={boxStyle}>
            <Container sx={styles.comaprisonContainer}>
                <Box sx={styles.contentWrapper}>
                    <Box sx={styles.content}>
                        <SectionHeader
                            title="See why Credify beats the rest"
                            description="It's easy to get the best mortgage with Credify."
                            sx={{ margin: '0 auto', textAlign: 'center' }}
                        />
                    </Box>
                    <Box sx={styles.table}>
                        <Table />
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default ComparisonTable;

const styles = {
    contentWrapper: {
        display: [null, null, null, 'grid', 'grid'],
        gridTemplateColumns: '1',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    comaprisonContainer: {
        padding: "0px"
    },
    content: {
        padding: "0px 20px"
    },
    text: {
        color: 'textSecondary',
        mt: ['30px', null, null, null, '42px', '40px', '60px'],
    },
    table: {
        mt: [30, 30, 30, 30, 30, 30, 30],
        padding: ["0px", "0px", "0px", "0px", "0px", "0px", "0px"]
    }
};
