import {
  Box,
  Container,
  // Button
} from 'theme-ui';
import SectionHeading from '../../components/section-heading';

const HowItWorks = () => {
  return (
    <Box as="section" sx={styles.section} variant="section.howItWorks" id="how-it-works">
      <Container>
        <SectionHeading
          title="How does it work?"
        // description="With just a few clicks, consumers can consent to a credit check, login with their CreditKarma account, and verify their data before sending it off."
        />
        <Box sx={styles.videoWrapper}>
          <h3>1. Borrowers complete a universal mortgage application form and Credify sources all supporting financial material automatically.</h3>
          <h3>2. Credify adds all other details a broker and lender will need, like property data and credit score.</h3>
          <h3>3. Licensed brokers make offers to supply mortgages to verified borrowers.</h3>
          <h3>4. The borrower chooses the broker supplying the deal they like best.</h3>
          <h3>5. The winning broker instantly gets all the data they need to complete fast.</h3>
          <br></br>
          <p>
            The time from seeing the borrower's details, to making an offer to supply the best rate available in their ciccumstances, to having the borrower choose their rate, to receiving a full, lender ready package of the borrower's data could be as little as 2 hours.
          </p>
        </Box>
      </Container>
    </Box>
  );
};

export default HowItWorks;

const styles = {
  heading: {
    maxWidth: '620px',
    mb: [5],
    h2: {
      letterSpacing: 'heading',
    },
  },
  explore: {
    textAlign: ['center'],
    mb: [6],
  },
  videoWrapper: {
    textAlign: 'center',
    position: 'relative',
    maxWidth: '80%',
    margin: '0 auto'
  },
  playPause: {
    color: 'white',
    fontWeight: 700,
    position: 'absolute',
    padding: '0px',
    bottom: [40, 40, 40, 70, 100],
    left: '50%',
    transform: 'translateX(-50%)',
    ':focus': {
      outline: 0,
    },
    img: {
      mr: '16px',
    },
  },
  demoGif: {
    '@media screen and (min-width: 500px)': {
      height: '800px',
    },
  }
};
