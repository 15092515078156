import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from "@sentry/react";
import TagManager from 'react-gtm-module';

Sentry.init({
  dsn: "https://f0f88be8c8aa4a3ebee52be3f007ebb7@o881781.ingest.sentry.io/5836267",

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_NODE_ENV
});

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_NODE_ENV === 'production' ? 'GTM-M75WWG4' : 'GTM-MH8LQKN'
}
TagManager.initialize(tagManagerArgs)

const loader = document.querySelector('.loader');
// if you want to show the loader when React loads data again
const showLoader = () => loader.classList.remove('loader--hide');
const hideLoader = () => loader.classList.add('loader--hide');

ReactDOM.render(
  <React.StrictMode>
    <App
      hideLoader={hideLoader}
      showLoader={showLoader}
    />
  </React.StrictMode>,
  document.getElementById('root')
)
