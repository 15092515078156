import { Box, Link, Container, Heading, Flex } from 'theme-ui';
import ReactMarkdown from 'react-markdown'
import MarkdownContent from './markdown'
import rehypeRaw from 'rehype-raw'


// See https://remarkjs.github.io/react-markdown/

const RateGuarantee = () => {
  return (
    <Box id="company-info" as="section" variant="section.page">
      <Container>
        <Box sx={styles.contentWrapper}>
          <Box sx={styles.content}>
            <Heading sx={styles.title}>
              Best Rate Guarantee Terms & Conditions
            </Heading>
            <Box sx={styles.buttonContainer}>
              <Link variant="primary" sx={styles.button} href={`https://approve${process.env.REACT_APP_NODE_ENV === 'staging' ? '-staging4nf' : ''}.getcredify.com`}>
                Get Turbo Approved Now
              </Link>
            </Box>
            <Flex>
              <div style={{ width: '100%', margin: '0 auto', marginTop: 0, marginBottom: 20 }}>
                <ReactMarkdown rehypePlugins={[rehypeRaw]} children={MarkdownContent} />
              </div>
            </Flex>
            <Box sx={styles.buttonContainer}>
              <Link variant="primary" sx={styles.button} href={`https://approve${process.env.REACT_APP_NODE_ENV === 'staging' ? '-staging4nf' : ''}.getcredify.com`}>
                Get Turbo Approved Now
              </Link>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default RateGuarantee;

const styles = {
  contentWrapper: {
    display: [null, null, null, 'flex', 'grid'],
    gridTemplateColumns: '1',
    alignItems: 'flex-start',
    justifyContent: 'center',
    pt: [100, null, null, 120, 130, 120, 150],
    pb: [null, null, null, null, 100, 100, 100],
  },
  content: {
    maxWidth: [507, null, null, 800, 800],
  },
  text: {
    fontSize: ['14px', '14px', '14px', '16px', '16px', '22px'],
    lineHeight: [1.85, 1.85, 1.85, 1.85, 1.85, 2.33],
    color: 'textSecondary',
    mt: ['30px', null, null, null, '42px', '40px', '60px'],
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: ['30px', null, null, null, '42px', '40px', '50px'],
    lineHeight: 1.33,
    letterSpacing: '-1px',
    color: 'textSecondary',
  },
  buttonContainer: {
    display: 'grid',
    gridTemplateColumns: '1',
    alignItems: 'flex-start',
    justifyContent: 'center',
    my: '15px',
  },
  button: {
    display: 'flex',
    justifyContent: "center",
    width: '100%',
    mt: 0,
    px: 40,
    borderRadius: 5,
    color: "#FFF",
    backgroundColor: "primary",
    lineHeight: '50px',
    height: '50px',
    fontSize: '16px',
    fontWeight: '300',
    textDecoration: "none",
  },
  hr: {
    border: 'none'
  }
};
