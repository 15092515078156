import { Box, Container, Heading, Text, Button, Grid, Image, Flex, Link } from 'theme-ui';
import { keyframes } from '@emotion/core';
import ReactMarkdown from 'react-markdown'
import SectionHeader from '../../components/section-heading';
import theme from '../../theme';
import onYourOwn from '../../assets/images/on-your-own.png';
import doneForYou from '../../assets/images/done-for-you.png';
import doneWithYou from '../../assets/images/done-with-you.png';

const optionsJson = [
    {
        title: 'Complete your own application',
        subtitle: "Connect your financial accounts, answer some simple questions, and we'll guide you through the application.",
        buttonText: 'Start for Free',
        image: onYourOwn
    },
    {
        title: 'Live experts can help with your application',
        subtitle: "Get advice and answers as you go by connecting to our experts via live chat. Our team is standing by.",
        buttonText: 'Start for Free',
        image: doneWithYou
    },
    {
        title: 'We complete your application for you',
        subtitle: "Have a dedicated mortgage expert handle everything, from start to finish. You're in good hands.",
        buttonText: 'Start for Free',
        image: doneForYou
    }
]

const MarkdownContent =
    `
## Need to move fast to secure your dream home?
Once pre-approved, you can go home shopping immediately and can begin the process to become a cash buyer. Cash buyers enjoy an average 3% to 4% cash discount, 4x greater home offer acceptance rate and completion times as fast as 72 hours.
`

const TheWayYouWant = (props) => {
    let boxStyle = props.box === 'alternatingBox' ? theme.styles.alternatingBox : theme.styles.sectionBox;
    return (
        <Box id="the-way-you-want" as="section" variant="section.page" sx={boxStyle}>
            <Container>
                <Box sx={styles.contentWrapper}>
                    <Box sx={styles.content}>
                        <SectionHeader
                            title="Amazing Customer Support"
                            description="We’re here to help at each stage of the process via live chat, email and phone support to ensure you get everything right, first time."
                            sx={{ margin: '0 auto', textAlign: 'center' }}
                        />
                        <Grid sx={styles.priceWrapper}>
                            {optionsJson.map((option, index) => (
                                <Box key={index} sx={styles.priceTable} className={'priceCard'}>
                                    <Box sx={styles.header}>
                                        <Box className="priceHeader">
                                            <Heading as="h3" sx={styles.title}>
                                                {option.title}
                                            </Heading>
                                            <Text as="p" sx={styles.subtitle}>
                                                {option.subtitle}
                                            </Text>
                                        </Box>
                                    </Box>
                                    <Box style={{ textAlign: 'center', marginTop: 20 }} className="priceButton">
                                        <Button sx={styles.button} variant="primaryMd" onClick={() => window.location.href = `https://approve${process.env.REACT_APP_NODE_ENV === 'staging' ? '-staging4nf' : ''}.getcredify.com`}>
                                            {option.buttonText}
                                        </Button>
                                    </Box>
                                    <Image src={option.image} alt={option.title} sx={styles.image} />
                                </Box>
                            ))}
                        </Grid>
                        <Link className='borrowerCta callingCta' data-lum="action" variant="primary" sx={styles.callButton} href='tel:+18005576196'>
                            Not sure? Call Us Now
                        </Link>
                        <Flex sx={styles.markdown}>
                            <div sx={styles.markdown}>
                                <ReactMarkdown sx={styles.markdown} children={MarkdownContent} />
                            </div>
                        </Flex>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default TheWayYouWant;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const fadeIn2 = keyframes`
  from {
    transform: translateY(50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const styles = {
    markdown: {
        textAlign: ["justify", null, null, "left"],
        maxWidth: [507, null, null, 700, 700],
        margin: '0 auto'
    },
    contentWrapper: {
        display: [null, null, null, 'flex', 'grid'],
        gridTemplateColumns: '1',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    content: {
        maxWidth: [null, null, null, 800, 1000],
    },
    priceWrapper: {
        mt: [50, 50, 50, 50, 50, 50, 50],
        mb: [40, 40, 40, 40, 40, 80, 80],
        gap: 30,
        display: 'grid',
        gridTemplateColumns: [
            'repeat(1, 340px)',
            'repeat(1, 340px)',
            'repeat(1, 340px)',
            'repeat(2,1fr)',
            'repeat(3, 320px)',
            'repeat(3, 330px)',
            'repeat(3, 370px)',
        ],
        justifyContent: 'center',
        '.priceCard': {
            '.priceHeader': {
                animation: `${fadeIn} 0.8s linear`,
            },
            'ul > li': {
                animation: `${fadeIn2} 0.7s linear`,
            },
            '.priceAmount': {
                animation: `${fadeIn} 0.9s linear`,
            },
            '.priceButton': {
                animation: `${fadeIn2} 0.7s linear`,
            },
        },
    },
    title: {
        fontWeight: 700,
        fontSize: 22,
        letterSpacing: '-0.55px',
    },
    subtitle: {
        mt: '6px',
    },
    image: {
        mt: 20,
        height: 200,
    },
    priceTable: {
        display: "grid",
        maxWidth: '350px',
        justifyItems: "center",
        backgroundColor: `#F9F9F9`,
        border: "1px solid #EEE",
        alignContent: "space-between",
        textAlign: 'center',
        borderRadius: 10,
        position: 'relative',
        padding: '20px 20px 0px 20px',
    },
    callButton: {
        display: ['flex', 'flex', 'flex', 'flex', 'flex', 'none', 'none'],
        justifyContent: "center",
        margin: '0 auto',
        width: ['100%', '75%', '60%'],
        mb: 30,
        borderRadius: 5,
        color: "#FFF",
        backgroundColor: "primary",
        lineHeight: '50px',
        height: '50px',
        fontSize: ['15px', '20px', '20px', '15px', '18px', '20px'],
        fontWeight: '500',
        textDecoration: "none",
        pr: 15,
        ":hover": {
            backgroundColor: "dark"
        }
    },
};
