import React, { useState, Suspense } from 'react';
import { ThemeProvider } from 'theme-ui';
import { useLocation } from 'react-router-dom'
import theme from '../theme';
import Layout from '../components/layout';
import { Tab, Loader } from 'semantic-ui-react'

const Affordability = React.lazy(() => import('../tools/affordability'));
const Amortization = React.lazy(() => import('../tools/amortization'));
const DebtToIncome = React.lazy(() => import('../tools/debtToIncome'));
const Payment = React.lazy(() => import('../tools/payment'));
const Refinance = React.lazy(() => import('../tools/refinance'));

const CalculatorsPage = () => {
    const panes = [
        {
            pathname: 'affordability',
            menuItem: 'Affordability',
            render: () => <Tab.Pane attached><Affordability /></Tab.Pane>,
        }, {
            pathname: 'amortization',
            menuItem: 'Amortization',
            render: () => <Tab.Pane attached><Amortization /></Tab.Pane>,
        }, {
            pathname: 'dti',
            menuItem: 'Debt to Income',
            render: () => <Tab.Pane attached><DebtToIncome /></Tab.Pane>,
        }, {
            pathname: 'payment',
            menuItem: 'Payment',
            render: () => <Tab.Pane attached><Payment /></Tab.Pane>,
        }, {
            pathname: 'refinance',
            menuItem: 'Refinance',
            render: () => <Tab.Pane attached><Refinance /></Tab.Pane>,
        }
    ]

    const location = useLocation();
    const splitPaths = location.pathname.split('/')
    const lastPath = splitPaths[splitPaths.length - 1]
    const [activeIndex, setActiveIndex] = useState(panes.find((pane) => pane.pathname === lastPath) ? panes.findIndex((pane) => pane.pathname === lastPath) : 0);

    return (
        <ThemeProvider theme={theme}>
            <Layout>
                <Suspense fallback={<div style={{ height: '100vh', textAlign: 'center' }}><Loader /></div>}>
                    <Tab
                        activeIndex={activeIndex}
                        className='CalculatorsTab'
                        menu={{ tabular: true, attached: true, stackable: true }}
                        panes={panes}
                        onTabChange={(e, { activeIndex }) => setActiveIndex(activeIndex)}
                    />
                </Suspense>
            </Layout>
        </ThemeProvider>
    )
}

export default CalculatorsPage;
