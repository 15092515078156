import React from 'react';
import { ThemeProvider } from 'theme-ui';
import theme from '../theme';
import Layout from '../components/layout';
import Disclosures from '../sections/disclosures-licensing/page';

const DisclosuresPage = () => {
    return (
        <ThemeProvider theme={theme}>
            <Layout>
                <Disclosures />
            </Layout>
        </ThemeProvider>
    )
}

export default DisclosuresPage;
