import { ThemeProvider } from 'theme-ui';
import theme from '../theme';
import Layout from '../components/layout';
import BrokerBanner from '../sections/brokers/banner';
import BrokerFeatures from '../sections/brokers/features';
import BrokerHowItWorks from '../sections/brokers/how-it-works';
// import BrokerPricing from '../sections/brokers/pricing';
import BrokerFaq from '../sections/brokers/faq';

const Brokers = () => {
    return (
        <ThemeProvider theme={theme}>
            <Layout>
                <BrokerBanner />
                <BrokerFeatures />
                <BrokerHowItWorks />
                {/* <BrokerPricing /> */}
                <BrokerFaq />
            </Layout>
        </ThemeProvider>
    )
}

export default Brokers;