import { Box, Container } from 'theme-ui';
import SectionHeading from '../../components/section-heading';
import Feature from '../../components/cards/feature';

import bulb from '../../assets/images/icons/bulb.png';
import dart from '../../assets/images/icons/dart.png';
import rocket from '../../assets/images/icons/rocket.png';
import trophy from '../../assets/images/icons/trophy.png';
import award from '../../assets/images/icons/award.png';

const data = [
  {
    id: 1,
    icon: dart,
    title: 'It’s Free!',
    description: 'Placing your mortgage on Credify is FREE!',
  },
  {
    id: 2,
    icon: bulb,
    title: 'It’s Easy',
    description: 'Complete a mortgage application and connect your accounts with Plaid. Credify does the rest!',
  },
  {
    id: 3,
    icon: rocket,
    title: 'It’s Effortless!',
    description: 'Get pre-approved offers fast from brokers and lenders covering the entire mortgage market without lifting a finger after you submit your application.',
  },
  {
    id: 4,
    icon: trophy,
    title: 'It’s Unbeatable...',
    description: 'The best rates come to you. All you have to do is choose the mortgage offer you like best.',
  },
  {
    id: 5,
    icon: award,
    title: '...and it’s Wholesale too!',
    description: 'Credify’s proprietary technology enables wholesale offers at below best market rates.',
  },
];

const HowItWorksFeatures = () => {
  return (
    <Box as="section" id="features" variant="section.features">
      <Container>
        <SectionHeading
          title="Credify is the best way to get a mortgage."
          description="You'll never want to try anything else again."
        />
        <Box sx={styles.features}>
          {data?.map((item) => (
            <Feature key={item.id} data={item} />
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default HowItWorksFeatures;

const styles = {
  heading: {
    marginBottom: [60, 60, 60, 80],
  },
  features: {
    gap: ['35px 60px', 60, 60, 40, 30, 60],
    display: ['grid', 'grid'],
    gridTemplateColumns: [
      'repeat(1, 1fr)',
      'repeat(1, 1fr)',
      'repeat(1, 1fr)',
      'repeat(3, 1fr)',
      'repeat(5, 1fr)',
    ],
  },
};
