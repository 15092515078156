const MarkdownContent =
`
The website located at getcredify.com (the “Site”) is a copyrighted work belonging to Credify Corporation (“Credify”, “us”, “our”, and “we”).  Certain features of the Site may be subject to additional guidelines, terms, or rules, which will be posted on the Site in connection with such features.  All such additional terms, guidelines, and rules are incorporated by reference into these Terms.

THESE TERMS OF USE (THESE “TERMS”) SET FORTH THE LEGALLY BINDING TERMS AND CONDITIONS THAT GOVERN YOUR USE OF THE SITE.  BY ACCESSING OR USING THE SITE, YOU ARE ACCEPTING THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT), AND YOU REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT).  YOU MAY NOT ACCESS OR USE THE SITE OR ACCEPT THE TERMS IF YOU ARE A UNITED STATES RESIDENT AND ARE AT LEAST 18 YEARS OLD.  IF YOU DO NOT AGREE WITH ALL OF THE PROVISIONS OF THESE TERMS, DO NOT ACCESS AND/OR USE THE SITE.

THESE TERMS REQUIRE THE USE OF ARBITRATION (SECTION 10.2) ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS, AND ALSO LIMIT THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF A DISPUTE.

## 1. Accounts

### 1.1 Account Creation

In order to use certain features of the Site, you must register for an account (“Account”) and provide certain information about yourself as prompted by the account registration forms.  Additionally, Credify requires every borrower user to satisfy certain Know Your Customer and Anti-Money Laundering requirements pursuant to Credify’s KYC/AML policy. You represent and warrant that: (a) all information you submit is truthful and accurate; (b) you will maintain the accuracy of such information. You may delete your Account at any time, for any reason, by following the instructions on the Site.  Credify may suspend or terminate your Account in accordance with Section 9.

### 1.2 Account Responsibilities

You are responsible for maintaining the confidentiality of your Account login information and are fully responsible for all activities that occur under your Account.  You agree to immediately notify Credify of any unauthorized use, or suspected unauthorized use of your Account or any other breach of security.  Credify cannot and will not be liable for any loss or damage arising from your failure to comply with the above requirements.

## 2. Access to the Site

### 2.1 License

Subject to these Terms, Credify grants you a non-transferable, non-exclusive, revocable, limited license to use and access the Site solely for your own personal, noncommercial use, but only in accordance with these Terms. You agree that you will not use or attempt to use this application for any purpose other than conducting mortgage banking related business with Credify; you may not use or attempt to use this application or any part of this application for any other purpose.

### 2.2 Certain Restrictions

The rights granted to you in these Terms are subject to the following restrictions: (a) you shall not license, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Site, whether in whole or in part, or any content displayed on the Site; (b) you shall not modify, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Site; (c) you shall not access the Site in order to build a similar or competitive website, product, or service; and (d) except as expressly stated herein, no part of the Site may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means.  Unless otherwise indicated, any future release, update, or other addition to functionality of the Site shall be subject to these Terms.  All copyright and other proprietary notices on the Site (or on any content displayed on the Site) must be retained on all copies thereof.

### 2.3 Acceptable Use Policy

The following terms constitute our “Acceptable Use Policy”:

(a) In addition, you agree not to:

(i) upload, transmit, or distribute to or through the Site any computer viruses, worms, or any software intended to damage or alter a computer system or data;

(ii) send through the Site unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of duplicative or unsolicited messages, whether commercial or otherwise;

(iii)  use the Site to harvest, collect, gather or assemble information or data regarding other users, including e-mail addresses, without their consent;

(iv) interfere with, disrupt, or create an undue burden on servers or networks connected to the Site, or violate the regulations, policies or procedures of such networks;

(v) attempt to gain unauthorized access to the Site (or to other computer systems or networks connected to or used together with the Site), whether through password mining or any other means;

(vi) harass or interfere with any other user’s use and enjoyment of the Site; or

(vii) use software or automated agents or scripts to produce multiple accounts on the Site, or to generate automated searches, requests, or queries to (or to strip, scrape, or mine data from) the Site (provided, however, that we conditionally grant to the operators of public search engines revocable permission to use spiders to copy materials from the Site for the sole purpose of and solely to the extent necessary for creating publicly available searchable indices of the materials, but not caches or archives of such materials, subject to the parameters set forth in our robots.txt file).

### 2.4 Modification

Credify reserves the right, at any time, to modify, suspend, or discontinue the Site (in whole or in part) with or without notice to you.  You agree that Credify will not be liable to you or to any third party for any modification, suspension, or discontinuation of the Site or any part thereof.

### 2.5 No Support or Maintenance

You acknowledge and agree that Credify will have no obligation to provide you with any support or maintenance in connection with the Site.

### 2.6 Ownership

You acknowledge that all the intellectual property rights, including copyrights, patents, trade marks, and trade secrets, in the Site and its content are owned by Credify or Credify’s suppliers.  Neither these Terms (nor your access to the Site) transfers to you or any third party any rights, title or interest in or to such intellectual property rights, except for the limited access rights expressly set forth in Section 2.1. Credify and its suppliers reserve all rights not granted in these Terms.  There are no implied licenses granted under these Terms.

### 2.7 Enforcement

We reserve the right (but have no obligation) to investigate and/or take appropriate action against you in our sole discretion if you violate any provision of these Terms or otherwise create liability for us or any other person. Such action may include terminating your Account in accordance with Section 9, and/or reporting you to law enforcement authorities.

### 2.8 Feedback

If you provide Credify with any feedback or suggestions regarding the Site (“Feedback”), you hereby assign to Credify all rights in such Feedback and agree that Credify shall have the right to use and fully exploit such Feedback and related information in any manner it deems appropriate.  Credify will treat any Feedback you provide to Credify as non-confidential and non-proprietary.  You agree that you will not submit to Credify any information or ideas that you consider to be confidential or proprietary.

### 2.9 Testimonials, Ratings & Reviews

You represent and warrant that you shall not submit any testimonial, rating or review that is known by you to be false, inaccurate or misleading; that infringes any third party’s copyright, patent, trademark, trade secret or other proprietary rights or rights of publicity or privacy; that violates any law, statute, ordinance or regulation (including, but not limited to, those governing export control, consumer protection, unfair competition, anti-discrimination or false advertising); that is, or may reasonably be considered to be, defamatory, libelous, hateful, racially or religiously biased or offensive, unlawfully threatening or unlawfully harassing to any individual, partnership or corporation; for which you were compensated or granted any consideration by any third party; or that includes any information that references other websites, addresses, email addresses, contact information or phone numbers.

You acknowledge and agree that all testimonials, ratings and reviews submitted to Credify will become and remain our exclusive property, even if the provisions of these Terms of Use are later modified or terminated. This means that you irrevocably grant to Credify the unrestricted right (now and in the future, without notice, compensation or other obligation to you or any other person) to use your statement, image, likeness, as they may be used, in any medium, in connection with an advertisement or for any other publicity purpose. You further agree that Credify may use any portion of your testimonial, in any way that it sees fit, and may exclude your name or use a fictitious name in connection therewith.

### 2.10 Aggregate/Anonymous Data

Credify may generate and collect learnings, logs and data regarding use of the Site (“Aggregate/Anonymous Data”). You agree that Credify may use Aggregate/Anonymous Data for any business purpose, before or after termination of these terms, including without limitation to develop and improve other products and services, so long as the Aggregate/Anonymous Data does not personally identify you.

## 3. Third Party Services

### 3.1 Third-Party Services

You acknowledge that the Site will enable or assist you to access, interact with, and/or purchase services from third parties, including loan brokers and lenders (collectively, “Third-Party Services”). Should you opt to use the Third Party Services, you will be subject to separate or additional terms of such Third-Party Services and you will be required to enter into a separate written agreement with the third party for the provision of the Third-Party Services. You shall comply in all respects with all applicable terms of the Third-Party Services that you access or subscribe to through the Site.

In connection with the Third-Party Services, you may access content licensed by Credify from third parties (“Third Party Content”). Such Third Party Content shall be subject to the license set forth above and you additionally agree not to use the Site, or any part thereof, to infringe, violate or misappropriate any intellectual property rights and/or other proprietary rights of any third party into the Third Party Content;

In order for Credify to distribute your information and facilitate contacts between you and the Third-Party Services, you hereby grant Credify permission to disclose any and all of the information that you provide to Credify to any such Third-Party Services. Any information that you provide to Credify must be true, complete, and accurate, must not represent you as someone else or falsely identify you, and must not violate any law, statute, ordinance or regulation. You are solely responsible for such information and for all of the terms and conditions of transactions between you and such Third-Party Services, including, without limitation, terms regarding warranties, insurance, fees, taxes, titles and licenses.

You acknowledge that Credify is not a party to any agreement that you may make with a Third-Party Service, and that the Third-Party Service is solely responsible for its services to you. Third-Party Services are not under the control of Credify and we do not guarantee that a Third-Party Service will present you with satisfactory offers. You understand that requirements for a particular product or service, including a mortgage, are determined by the Third-Party Services and Credify is not responsible for any Third-Party Services.

Credify does not provide advisory services and is not your financial or legal advisor. You agree you will rely on your own judgment and research independently of any communications or information you receive from Credify in deciding which available product, terms and Third-Party Services best suit your needs and financial situation. You agree that Credify shall not be liable for any damages or costs which arise from your use of Third-Party Services.

If at any time any Third-Party Services cease to make their programs available to us on reasonable terms, we may cease to provide such features to you without entitling you to refund, credit, or other compensation.

### 3.2 Release

You hereby release and forever discharge the Credify (and our officers, employees, agents, successors, and assigns) from, and hereby waive and relinquish, each and every past, present and future dispute, claim, controversy, demand, right, obligation, liability, action and cause of action of every kind and nature (including personal injuries, death, and property damage), that has arisen or arises directly or indirectly out of, or that relates directly or indirectly to, the Site (including any interactions with, or act or omission of, other Site users or any Third-Party Services).

IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION 1542 IN CONNECTION WITH THE FOREGOING, WHICH STATES: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.”

## 4. Texas Licensed Mortgage Broker

Credify is licensed as a Mortgage Company in the State of Texas. Credify is not licensed in other states and will not originate mortgage loans for real property located in states other than Texas.

## 5. Indemnification

You agree to indemnify and hold Credify (and its officers, employees, and agents) harmless, including costs and attorneys’ fees, from any claim or demand made by any third party due to or arising out of (a) your use of the Site, (b) your violation of these Terms, or (c) your violation of applicable laws or regulations.  Credify reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate with our defense of these claims.  You agree not to settle any matter without the prior written consent of Credify.  Credify will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.

## 6. Disclaimers

THE SITE IS PROVIDED ON AN “AS-IS” AND “AS AVAILABLE” BASIS, AND COMPANY (AND OUR SUPPLIERS) EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING ALL WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT.  WE (AND OUR SUPPLIERS) MAKE NO WARRANTY THAT THE SITE WILL MEET YOUR REQUIREMENTS, WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS, OR WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE.  IF APPLICABLE LAW REQUIRES ANY WARRANTIES WITH RESPECT TO THE SITE, ALL SUCH WARRANTIES ARE LIMITED IN DURATION TO NINETY (90) DAYS FROM THE DATE OF FIRST USE.

SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.  SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.

## 7. Limitation on Liability

TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL COMPANY (OR OUR SUPPLIERS) BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFITS, LOST DATA, COSTS OF PROCUREMENT OF SUBSTITUTE PRODUCTS, OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO THESE TERMS OR YOUR USE OF, OR INABILITY TO USE, THE SITE, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  ACCESS TO, AND USE OF, THE SITE IS AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR COMPUTER SYSTEM, OR LOSS OF DATA RESULTING THEREFROM.

TO THE MAXIMUM EXTENT PERMITTED BY LAW, NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO THIS AGREEMENT (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE LIMITED TO A MAXIMUM OF FIFTY US DOLLARS (U.S. $50). THE EXISTENCE OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT.  YOU AGREE THAT OUR SUPPLIERS WILL HAVE NO LIABILITY OF ANY KIND ARISING FROM OR RELATING TO THIS AGREEMENT.

SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.

## 8. Communications With Credify

### 8.1 Electronic Communications

The communications between you and Credify use electronic means, whether you use the Site or send us emails, or whether Credify posts notices on the Site or communicates with you via email. For contractual purposes, you (a) consent to receive communications from Credify in an electronic form; and (b) agree that all terms and conditions, agreements, notices, disclosures, and other communications that Credify provides to you electronically satisfy any legal requirement that such communications would satisfy if it were be in a hardcopy writing. The foregoing does not affect your non-waivable rights.

### 8.2 Soft Inquiry

By creating an account and providing your date of birth or social security number, you are authorizing Credify to perform a soft inquiry on your credit. While the soft inquiry into your credit does not impact your credit score, mortgage brokers or lender may subsequently perform a “hard” inquiry which will appear on your credit report and may negatively affect your credit score.

### 8.3 E-Signature

General communications through this Site, chat or via Email are not intended by us to constitute either an electronic record or an electronic signature, or to constitute any agreement by the sender to conduct a transaction by electronic means, unless a specific statement to the contrary is included in the message and specific e-signature procedures are employed. However, your assent to a “click to accept” button or box is binding upon you.

### 8.4 Recording & Monitoring of Communications

Your communications with us via the Site, chat Email, and telephone may be recorded or monitored and by using such communications methods you are consenting to the recording or monitoring of the same.

## 9. Term and Termination

Subject to this Section, these Terms will remain in full force and effect while you use the Site.  We may suspend or terminate your rights to use the Site (including your Account) at any time for any reason at our sole discretion, including for any use of the Site in violation of these Terms.  Upon termination of your rights under these Terms, your Account and right to access and use the Site will terminate immediately.  You understand that any termination of your Account may involve deletion of any content associated with your Account from our live databases.  Credify will not have any liability whatsoever to you for any termination of your rights under these Terms, including for termination of your Account or deletion of your content.  Even after your rights under these Terms are terminated, the following provisions of these Terms will remain in effect: Sections 2.2 through 2.6, Section 4 through 10.

## 10. General

### 10.1 Changes

These Terms are subject to occasional revision, and if we make any substantial changes, we may notify you by sending you an e-mail to the last e-mail address you provided to us (if any), and/or by prominently posting notice of the changes on our Site.  You are responsible for providing us with your most current e-mail address.  In the event that the last e-mail address that you have provided us is not valid, or for any reason is not capable of delivering to you the notice described above, our dispatch of the e-mail containing such notice will nonetheless constitute effective notice of the changes described in the notice.  Any changes to these Terms will be effective upon the earlier of thirty (30) calendar days following our dispatch of an e-mail notice to you (if applicable) or thirty (30) calendar days following our posting of notice of the changes on our Site.  These changes will be effective immediately for new users of our Site.  Continued use of our Site following notice of such changes shall indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes.

### 10.2 Dispute Resolution

Please read this Arbitration Agreement carefully.  It is part of your contract with Credify and affects your rights.  It contains procedures for MANDATORY BINDING ARBITRATION AND A CLASS ACTION WAIVER.

(a) Applicability of Arbitration Agreement.  All claims and disputes (excluding claims for injunctive or other equitable relief as set forth below) in connection with the Terms or the use of any product or service provided by the Credify that cannot be resolved informally or in small claims court shall be resolved by binding arbitration on an individual basis under the terms of this Arbitration Agreement.  Unless otherwise agreed to, all arbitration proceedings shall be held in English.  This Arbitration Agreement applies to you and the Credify, and to any subsidiaries, affiliates, agents, employees, predecessors in interest, successors, and assigns, as well as all authorized or unauthorized users or beneficiaries of services or goods provided under the Terms.

(b) Notice Requirement and Informal Dispute Resolution.  Before either party may seek arbitration, the party must first send to the other party a written Notice of Dispute (“Notice”) describing the nature and basis of the claim or dispute, and the requested relief.  A Notice to the Credify should be sent to: 235 Grand Street #3603, Jersey City, NJ 07302.  After the Notice is received, you and the Credify may attempt to resolve the claim or dispute informally.  If you and the Credify do not resolve the claim or dispute within thirty (30) days after the Notice is received, either party may begin an arbitration proceeding.  The amount of any settlement offer made by any party may not be disclosed to the arbitrator until after the arbitrator has determined the amount of the award, if any, to which either party is entitled.

(c)  Arbitration Rules.  Arbitration shall be initiated through the American Arbitration Association (“AAA”), an established alternative dispute resolution provider (“ADR Provider”) that offers arbitration as set forth in this section.  If AAA is not available to arbitrate, the parties shall agree to select an alternative ADR Provider.  The rules of the ADR Provider shall govern all aspects of the arbitration, including but not limited to the method of initiating and/or demanding arbitration, except to the extent such rules are in conflict with the Terms.  The AAA Consumer Arbitration Rules (“Arbitration Rules”) governing the arbitration are available online at www.adr.org or by calling the AAA at 1-800-778-7879.  The arbitration shall be conducted by a single, neutral arbitrator.  Any claims or disputes where the total amount of the award sought is less than Ten Thousand U.S. Dollars (US $10,000.00) may be resolved through binding non-appearance-based arbitration, at the option of the party seeking relief.  For claims or disputes where the total amount of the award sought is Ten Thousand U.S. Dollars (US $10,000.00) or more, the right to a hearing will be determined by the Arbitration Rules.  Any hearing will be held in a location within the United States within 100 miles of your residence, and unless the parties agree otherwise.  If you reside outside of the U.S., any hearing will be held in Jersey City, NJ. Any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.  If the arbitrator grants you an award that is greater than the last settlement offer that the Credify made to you prior to the initiation of arbitration, the Credify will pay you the greater of the award or $2,500.00.  Each party shall bear its own costs (including attorney’s fees) and disbursements arising out of the arbitration and shall pay an equal share of the fees and costs of the ADR Provider.

(d) Additional Rules for Non-Appearance Based Arbitration.  If non-appearance based arbitration is elected, the arbitration shall be conducted by telephone, online and/or based solely on written submissions; the specific manner shall be chosen by the party initiating the arbitration.  The arbitration shall not involve any personal appearance by the parties or witnesses unless otherwise agreed by the parties.

(e)  Time Limits.  If you or the Credify pursue arbitration, the arbitration action must be initiated and/or demanded within the statute of limitations (i.e., the legal deadline for filing a claim) and within any deadline imposed under the AAA Rules for the pertinent claim.

(f)   Authority of Arbitrator.  If arbitration is initiated, the arbitrator will decide the rights and liabilities, if any, of you and the Credify, and the dispute will not be consolidated with any other matters or joined with any other cases or parties.  The arbitrator shall have the authority to grant motions dispositive of all or part of any claim.  The arbitrator shall have the authority to award monetary damages, and to grant any non-monetary remedy or relief available to an individual under applicable law, the AAA Rules, and the Terms.  The arbitrator shall issue a written award and statement of decision describing the essential findings and conclusions on which the award is based, including the calculation of any damages awarded.  The arbitrator has the same authority to award relief on an individual basis that a judge in a court of law would have.  The award of the arbitrator is final and binding upon you and the Credify.

(g) Waiver of Jury Trial.  THE PARTIES HEREBY WAIVE THEIR CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY, instead electing that all claims and disputes shall be resolved by arbitration under this Arbitration Agreement.  Arbitration procedures are typically more limited, more efficient and less costly than rules applicable in a court and are subject to very limited review by a court.  In the event any litigation should arise between you and the Credify in any state or federal court in a suit to vacate or enforce an arbitration award or otherwise, YOU AND THE COMPANY WAIVE ALL RIGHTS TO A JURY TRIAL, instead electing that the dispute be resolved by a judge.

(h) Waiver of Class or Consolidated Actions.  ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS, AND CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER.

(i)   Confidentiality.  All aspects of the arbitration proceeding, including but not limited to the award of the arbitrator and compliance therewith, shall be strictly confidential.  The parties agree to maintain confidentiality unless otherwise required by law.  This paragraph shall not prevent a party from submitting to a court of law any information necessary to enforce this Agreement, to enforce an arbitration award, or to seek injunctive or equitable relief.

(j)   Severability.  If any part or parts of this Arbitration Agreement are found under the law to be invalid or unenforceable by a court of competent jurisdiction, then such specific part or parts shall be of no force and effect and shall be severed and the remainder of the Agreement shall continue in full force and effect.

(k) Right to Waive.  Any or all of the rights and limitations set forth in this Arbitration Agreement may be waived by the party against whom the claim is asserted.  Such waiver shall not waive or affect any other portion of this Arbitration Agreement.

(l)   Survival of Agreement.  This Arbitration Agreement will survive the termination of your relationship with Credify.

(m)  Small Claims Court.  Notwithstanding the foregoing, either you or the Credify may bring an individual action in small claims court.

(n) Emergency Equitable Relief.  Notwithstanding the foregoing, either party may seek emergency equitable relief before a state or federal court in order to maintain the status quo pending arbitration.  A request for interim measures shall not be deemed a waiver of any other rights or obligations under this Arbitration Agreement.

(o) Claims Not Subject to Arbitration.  Notwithstanding the foregoing, claims of defamation, violation of the Computer Fraud and Abuse Act, and infringement or misappropriation of the other party’s patent, copyright, trademark or trade secrets shall not be subject to this Arbitration Agreement.

(p) Courts.  In any circumstances where the foregoing Arbitration Agreement permits the parties to litigate in court, the parties hereby agree to submit to the personal jurisdiction of the courts located within Hudson County, New Jersey, for such purpose

### 10.3 Applicable Law

The laws of the State of New Jersey, excluding its choice of law provisions, will govern these Terms and any dispute that arises between you and Credify.

### 10.4 Export

The Site may be subject to U.S. export control laws and may be subject to export or import regulations in other countries. You agree not to export, reexport, or transfer, directly or indirectly, any U.S. technical data acquired from Credify, or any products utilizing such data, in violation of the United States export laws or regulations.

### 10.5 Disclosures

Credify is located at the address in Section 10.8. If you are a California resident, you may report complaints to the Complaint Assistance Unit of the Division of Consumer Product of the California Department of Consumer Affairs by contacting them in writing at 400 R Street, Sacramento, CA 95814, or by telephone at (800) 952-5210.

### 10.6 Entire Terms

These Terms constitute the entire agreement between you and us regarding the use of the Site. Our failure to exercise or enforce any right or provision of these Terms shall not operate as a waiver of such right or provision. The section titles in these Terms are for convenience only and have no legal or contractual effect. The word “including” means “including without limitation”.  If any provision of these Terms is, for any reason, held to be invalid or unenforceable, the other provisions of these Terms will be unimpaired and the invalid or unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law.  Your relationship to Credify is that of an independent contractor, and neither party is an agent or partner of the other.  These Terms, and your rights and obligations herein, may not be assigned, subcontracted, delegated, or otherwise transferred by you without Credify’s prior written consent, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void.  Credify may freely assign these Terms.  The terms and conditions set forth in these Terms shall be binding upon assignees.

### 10.7 Copyright/Trademark Information

Copyright © 2022 Credify Corporation All rights reserved.

All trademarks, logos and service marks (“Marks”) displayed on the Site are our property or the property of other third parties.

You are not permitted to use these Marks without our prior written consent or the consent of such third party which may own the Marks.

## 11. CONTACT INFORMATION.

We welcome your comments or questions about these terms.

You may also contact us at our address:

Credify Corporation, 235 Grand Street #3603, Jersey City, NJ 07302

Email: [hello@getcredify.com](mailto:hello@getcredify.com)

Phone: +1 800 557 6196
`

export default MarkdownContent
