import React from 'react';
import { ThemeProvider } from 'theme-ui';
import theme from '../theme';
import Layout from '../components/layout';
import RateGuarantee from '../sections/rate-guarantee/page';

const AboutUsPage = () => {
    return (
        <ThemeProvider theme={theme}>
            <Layout>
                <RateGuarantee />
            </Layout>
        </ThemeProvider>
    )
}

export default AboutUsPage;
