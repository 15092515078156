const MarkdownContent =
    `
NOTICE: THIS IS A CONTRACT BINDING YOU TO THE TERMS CONTAINED HEREIN. BY MANIFESTING YOUR ASSENT TO THIS CONTRACT (WHETHER BY ESTABLISHING AN ACCOUNT, AFFIRMATIVELY CLICKING ON THE “I AGREE” BUTTON BELOW, OR BY OTHERWISE ACCESSING OR UTILIZING THE CREDIFY SERVICES OFFERED BY CREDIFY CORPORATION THROUGH THE GETCREDIFY.COM WEBSITE OR THE CREDIFY APPLICATION) YOU ACCEPT AND AGREE TO THE OBLIGATIONS CREATED BY THIS CONTRACTUAL AGREEMENT IN THEIR ENTIRETY. YOU FURTHER ACKNOWLEDGE AND RECOGNIZE THAT THIS AGREEMENT IS BASED UPON GOOD AND VALUABLE CONSIDERATION AND YOU HEREBY ACKNOWLEDGE THE RECEIPT AND SUFFICIENCY OF THAT CONSIDERATION. IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT UTILIZE THESE SERVICES.

## I. Parties and Agreement:

This Contract is entered into between Credify Corporation (“Credify,” “us,” “we,” “our” or “ours”), which has its principal place of business at 235 Grand Street #3603, Jersey City, NJ 07302, and you (“Customer,” “you,” “your,” or “yours”), as the subscriber or user of services rendered through our loan-origination platform available through our website getcredify.com or Credify’s mobile application (collectively, the “Parties”). If you purport to be acting on behalf of any other person, company, or entity, you hereby warrant and affirm that you have the actual authority to so act for that person, company, or entity, and agree to assume all responsibilities created by this Agreement if the person, company, or entity you purport to represent disclaims your representation or is found to have not acted through your actions. Capitalized terms used in this Agreement have the meaning provided in the Definitions section of this Agreement.

By using this Service, accessing the Credify website’s Subscriber Areas, or utilizing the Services or Software made available through the Credify website or the Credify’s mobile application, you manifest your agreement to the terms contained in this Agreement and the Terms of Use and Privacy Policy made available on the Credify website, as updated periodically. To the extent any conflict or difference exist between this Agreement and any Terms of Use or Privacy Policy contained on the Credify website, this document shall control. Credify reserves the right to update and change this Agreement from time to time without notice. Any new features implemented shall become subject to this Agreement. Continuing to use the Credify services after any such changes or feature implementations shall constitute consent to such changes and acceptance of any new, changed, or modified terms or provisions.

You may not alter, supplement, or amend this Agreement unless we agree in a written agreement signed by you and a duly authorized representative of Credify. Any attempt by you to alter, supplement, or amend this Agreement other than through a signed, written agreement (as described above) is invalid. If you do not agree to the terms of this Agreement, you may not utilize our services. Therefore, as set forth in this Agreement, the Parties agree as follows:

## II. Definitions:

For purposes of these Terms of Service, the following definitions shall apply:

“Access Credentials” means any username, identification number, password, license or security key, security token, PIN or other security code, method, technology, or device used, alone or in combination, to verify an individual’s identity and authorization to access and use the Credify Services.

“Agreement” means this Terms of Service contract between the Parties.

“Authorized User” means an individual who has agreed to this Agreement and who is authorized by Credify to utilize the Services pursuant to a subscription from a Customer.

“Borrowers” means Users of the Credify service who wish to obtain offers from Brokers or lenders regarding loan options or packages and are utilizing Credify’s services for individual or internal business purposes.

“Borrower Data” means, other than Borrower Data and Resultant Data, information, data and other content, in any form or medium, that is collected, downloaded or otherwise received, directly or indirectly from Borrower by or through the Services, or that incorporates or is derived from the Processing of such information, data or content by or through the Services.

“Broker” means a mortgage broker.

“Contract” has the same meaning as “Agreement.”

“Confidential Information” means any and all trade secrets, business and financial information, and other proprietary information or data disclosed between Credify and Customer, or incorporated in materials or products provided by or to Customer through the Credify services.

“Customer” means a Broker who has agreed to this Agreement, has purchased a subscription to the Services and is utilizing such Services for business purposes, for purposes relating to generating profit, or for any purpose other than non-business personal use.

“Customer Data” means information, data and other content, in any form or medium, including Customer-provided Confidential Information that is collected, downloaded or otherwise received, directly or indirectly from Customer or an Authorized User by or through the Services or that incorporates or is derived from the Processing of such information, data or content by or through the Services.

“Customer Systems” means the Customer’s information technology infrastructure, including computers, smartphones, software, hardware, databases, electronic systems (including database management systems) and networks, whether operated directly by Customer or through the use of third-party services.

“De-Identified” means to be processed or rendered in such a manner as to remove personal identifiable information such that the information cannot be used to identify an individual.

“Documentation” means any manuals, instructions or other documents or materials that the Credify provides or makes available to Customer in any form or medium and which describe the functionality, components, features or requirements of the Credify Services or Provider Materials, including any aspect of the installation, configuration, integration, operation, use, support or maintenance thereof.

“Intellectual Property Rights” means any and all registered and unregistered rights granted, applied for or otherwise now or hereafter in existence under or related to any patent, copyright, trademark, trade secret, database protection or other intellectual property rights laws, and all similar or equivalent rights or forms of protection, in any part of the world.

“Law” means any statute, law, ordiance, regulation, rule, code, order, constitution, treaty, common law, judgment, decree or other requirement of any federal, state, local or foreign government or political subdivision thereof, or any arbitrator, court or tribunal of competent jurisdiction.

“Lenders” means Customers or Users of the Credify service who wish to offer to Borrowers information regarding loan options or packages, or other services, being offered by Lenders.

“Losses” means any and all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs or expenses of whatever kind, including reasonable attorneys’ fees and the costs of enforcing any right to indemnification hereunder and the cost of pursuing any insurance providers.

“Parties” means the parties to this Agreement.

“Person” means an individual, corporation, partnership, joint venture, limited liability entity, governmental authority, unincorporated organization, trust, association or other entity.

“Privacy Policy” means the most recent version of the privacy policy posted on the getcredify.comwebsite as updated from time to time.

“Provider Materials” means the Service Software, Specifications, Documentation and Provider Systems and any and all other information, data, documents, materials, works and other content, devices, methods, processes, hardware, software and other technologies and inventions, including any deliverables, technical or functional descriptions, requirements, plans or reports, that are provided or used by Credify or any Subcontractor in connection with the Services or otherwise comprise or relate to the Services or Provider Systems. For the avoidance of doubt, Provider Materials include Resultant Data and any information, data or other content derived from Credify’s monitoring of Customer’s access to or use of the Services, but do not include Customer Data.

“Provider Systems” means the information technology infrastructure used by or on behalf of Credify in performing the Services, including all computers, software, hardware, databases, electronic systems (including database management systems) and networks, whether operated directly by Credify or through the use of third-party services.

“Representatives” means, with respect to a party, that party’s, and its affiliates’, employees, officers, directors, consultants, agents, independent contractors, service providers, sublicensees, subcontractors and legal advisors.

“Resultant Data” means data and information related to Borrower's use of the Services that is used by Provider in an aggregate and anonymized manner, including to compile statistical and performance information related to the provision, perform research, and operation of the Services.

“Service Software” means Credify’s Software and any third-party or other software, and all new versions, Updates, revisions, improvements and modifications of the foregoing, that Credify provides remote access to and use of as part of the Services.

“Services” means the Credify Software, program, website, application(s), functionality, or operations available through the Credify website or any other remotely accessible application or process.

“Software” means the compiled executable code for Credify mobile applications, Apps, web applications, servers, API’s, Services, service layers, integration services, databases, client software, configuration interfaces, web sites and backup services, including any Updates and Upgrades to the Software provided by Credify under these Terms.

“Subscriber Areas” means the Credify Services and/or those portions of the Credify website that only Authorized Users may utilize and which require the use of Access Credentials to access.

“Specifications” means the specifications for the Services set forth in the account types or edition-type descriptions provided for each on the Credify website and, to the extent consistent with and not limiting of the foregoing, any other Statements of Work or documentation associated therewith.

“Terms of Service” means this Terms of Service agreement, as updated from time to time and posted on the Credify website.

“Term” means the Initial Term and any Renewal Term.

“Update” means any software patches, bug fixes or other modifications or revisions to the Software that Credify releases to all other Software licensee customers without additional compensation.

“User” means any person who in any way uses the the Software or any of the Services (a User may also be a Borrower, a Customer or an Authorized User).

## III. Services Offered:

### A. Loan Origination Services
Credify offers Brokers through our Software the ability to respond to solicitations for services posted by eligible Borrowers with offers to originate loans. The pricing options to Brokers are as set forth from time to time on the Credify website. Customer must agree to the applicable fee schedules and pricing options, and render payment, before access will be granted.

### B. Payment of Applicable Fees
Upon payment of the applicable access fee(s) applicable to Customer and upon creation of Access Credentials, Customer will be granted access to the Services and will be able to establish User accounts for the number of Users authorized by the specific package purchased by Customer. Payment must be received and verified before access will be granted. Unless otherwise agreed to by Credify, all fees are due in advance and are payable in U.S. dollars. Customer is responsible for any taxes, assessments, or governmental fees associated with the subscription to and use of the Credify services.

### C. Service Levels and System Uptime
Credify will take commercially reasonable efforts to maintain system uptime and accessibility. Except as otherwise provided in the provisions of the specific package Specifications selected and purchased by Customer, however, Credify makes no guarantee that there will be no breaks in service or that services will always be available. Except as provided in the provisions of the specific package selected and purchased by Customer, Credify expressly disclaims any guarantee of or liability for any system failures, system downtime, system instability, or other accessibility problems and you agree to hold Credify harmless for any such incidents.

### D. Support
In order to address any technical issues, Customer agrees to email Credify at hello@getcredify.com.

## IV. Accounts, Duration, and Access to Services:

### A. Access to Services
Upon payment of the initial fee(s) by Customer, Credify grants to Customer, subject to all the terms and conditions of this Agreement a non-exclusive, non-transferable, non-sublicensable license to remotely access and use the Credify Services for the duration of the Term solely for internal business purposes.

### B. Subscription Renewal and Cancellation
The duration of this Agreement begins upon the granting of a license to a Customer to access the Credify Services, as described in Section III, A (above), and shall continue for an initial 30-day period (the “Initial Term”). Upon expiration of the Initial Term, this Agreement shall renew automatically for an additional period of equal duration (each such renewal period shall be referred to as a “Renewal Term”, and together with the Initial Term, the “Term”) unless cancelled by either party before the date of such automatic renewal. Payment for the Renewal Term will become due upon renewal. If Customer has previously provided a payment mechanism to satisfy applicable access fees or other fees or costs, Customer hereby authorizes Credify to bill Customer through the previously provided payment mechanism unless Customer provides alternate payment mechanism prior to the beginning of the Renewal Term. If the previously-provided payment mechanism fails, or if Customer’s account otherwise goes into arrears, Customer agrees that Credify may assess late fees of 1.5% of the outstanding balance per month on any and all past-due amount(s). If payment is not rendered when due, Credify is under no obligation to continue rendering services and may suspend, disable, or terminate past-due account at its sole discretion and without prior notice to Customer.

### C. Changes to Subscription Terms
Credify may change the price for the Service from time to time, and will communicate any price changes to Customer in advance and, if applicable, how to accept those changes. Price changes will take effect at the start of the next subscription period following the date of the price change. As permitted by local law, you accept the new price by continuing to use the Services after the price change takes effect. If you do not agree with the price changes, you have the right to reject the change by unsubscribing from the Services prior to the price change going into effect. Please therefore make sure you read any such notification of price changes carefully.

### D. Access Credentials
Access credentials are the sole responsibility of the Customer. Customers and Users are encouraged to use long and complex passwords of at least thirteen characters, consisting of upper-case, lower-case, numeric, and special characters, that have not been previously used in any way elsewhere, to prevent unauthorized access in the event of a “brute force,” “credential stuffing,” or other form of cyber attack. Customer assumes all risk and responsibility for creating passwords of sufficient length and complexity. Each User must have his or her own Access Credentials. The number of Users to whom access is granted (i.e., the number of Access Credentials authorized) is controlled entirely by the specific access package selected and purchased by the Customer. Access Credentials may not be disclosed to, shared with, or distributed to others, and you agree to take full responsibility for any activities or actions occurring under your account. If your credentials are lost, stolen, or otherwise compromised, you must immediately notify us either by email to hello@getcredify.com or by telephone at 347-308-2597.

### E. Account Responsibilities
User accounts are not transferable and you may not share your access credentials with others or allow others to use your account. You are responsible for maintaining the confidentiality of account login information and are fully responsible for all activities that occur under such account.  Customer agrees to immediately notify Credify of any unauthorized use, or suspected unauthorized use of the account or any other breach of security.  Credify cannot and will not be liable for any loss or damage arising from your failure to comply with the above requirements. IN THE EVENT THAT YOUR CREDENTIALS ARE USED TO ACCESS OUR SERVICES IN VIOLATION OF THIS PROVISION, EITHER WITH YOUR KNOWLEDGE OR THROUGH YOUR NEGLIGENCE, YOU HEREBY ACCEPT ANY ASSOCIATED LIABILITY AND AGREE TO INDEMNIFY CREDIFY FOR ANY AND ALL COSTS, EXPENDITURES, LIABILITIES, FINES, JUDGMENTS, PENALTIES, REIMBURSEMENTS, OR ASSESSMENTS ASSOCIATED WITH THE IMPROPER USE OF YOUR CREDENTIALS.

### F. Capacity
Credify may be utilized only by persons at least 18 years of age. This site and the Credify services are not intended for children or to be used by anyone under the age of 18. Persons who have been deemed incompetent by a court of law, or who otherwise lack contractual capacity, may not utilize the Credify website or the Credify services.

## V. Intellectual Property Rights:

### A. Ownership of Products and Software
All materials provided by Credify to Customer with respect to the Credify products or services, including but not limited to the Software, Confidential Information, proprietary data, proprietary documentation associated with the Credify products or services, or other proprietary information developed or provided by Credify, Credify, or any subsidiaries, affiliates, or agents thereof (including the underlying computer code of our product and/or its web-based application, or any hosted portion thereof; graphics; logos; icons; images; and any non-public methodologies, equipment, processes, or procedures used by Credify to deliver the Credify products and/or services, including any copyrights, trademarks, patents, trade secrets, and any other intellectual property rights inherent in and appurtenant to the Credify products and/or services), shall remain the sole and exclusive property of Credify. Except for any preexisting Customer Intellectual Property Rights, Customer Data, and Confidential Information provided by Customer under this Agreement, and subject to any third-party rights or restrictions, Credify will own all Intellectual Property Rights in or related to all deliverables that are developed or delivered by us hereunder or pursuant to any subsequent Statement of Work.

### B. Customer Data:

* i.   Title and Ownership of Customer Data: Customer shall retain title to and ownership of Customer Data but grants Credify (and its service providers and affiliates, to the extent those affiliates are working for Credify) a limited, irrevocable, non-exclusive, royalty-free, transferable, global license to access, store, process, and use Customer Data solely for the purposes of providing the Services and/or complying with the obligations of Credify under this Agreement.

* ii.  Retention of Customer Data: Upon termination of this Agreement for any reason, Credify may retain Customer Data for a reasonable limited period of time to facilitate business or operational needs, to assist with future Customer return to the Credify services, or for other legitimate purposes. Credify, however, is under no obligation to retain Customer Data and may irrevocably delete or otherwise dispose of Customer Data.

### C. Information Privacy and Data Security
Although data security risks cannot be completely eliminated, Credify will take commercially reasonable precautions to protect data and information provided to us or stored with us in connection with your use of the Credify Services. Your use of our Services and the accompanying decision to provide information to us, however, is completely voluntary. Accordingly, you agree to hold Credify and its Representatives harmless for any improper or unlawful acquisition of your information by any third party. You hereby agree to protect your computer system, network, or applications (the Customer Systems) from risks that could lead to data security incidents. In the event that a data security incident can be traced to a security deficiency or failure attributable to you or your computer system(s), YOU AGREE TO HOLD CREDIFY HARMLESS FOR ANY SUCH DATA SECURITY INCIDENT AND TO INDEMNIFY CREDIFY FOR ANY COSTS, FINES, FEES, ASSESSMENTS, OR OTHER EXPENDITURES INCURRED TO INVESTIGATE, REMEDIATE, OR OTHERWISE ADDRESS THE DATA SECURITY INCIDENT.

### D. Certain Restrictions
The rights granted to you in this Agreement are subject to the following restrictions: (a) you shall not alter or modify any part of the Credify Products, Software, or Services; (b) copy or duplicate, or permit a third party to copy or duplicate, any aspect of the Credify Products, Software, or Services; (c) modify, translate, or create derivative works of the Credify Products, Software, or Services; (d) decompile, decipher, reverse engineer, disassemble, or otherwise reduce or attempt to derive or discover the source code, algorithms, specifications, architecture, or other elements of the Credify Products, Software, or Services; (e) create any link to the Credify Services or frame or mirror the content contained thereon, or accessible from, the Credify platform; (f) create, control, or operate any service, company, entity, or operation competing directly with Credify’s Services; (g) sublicense, transfer, sell, resell, lease, assign, give, distribute or disclose the Credify Products, Software, or Services, or any license to access the Credify Products, Software, or Services, to any third party. The provisions of this section shall survive the expiration or termination of this Agreement.

## VI. Acceptable Use Policy:

### A. Unacceptable Use
Credify requires that all Customers and other Users of the Credify Services conduct themselves with respect toward others. Credify hereby implements and enforces the following Acceptable Use Policy, to which you agree by accepting the terms of this Agreement. Pursuant to that policy, the following rules apply to your use of the Service:

* i.   Abusive Behavior: Do not harass, threaten, or defame any person or entity. Do not contact any person who has requested no further contact. Do not use ethnic or religious slurs against any person or group.

* ii.  Privacy: Do not violate the privacy rights of any person. Do not collect or disclose any personal address, social security number, or other personally identifiable information without the person’s affirmative consent and permission. Do not cooperate in or facilitate identity theft or fraud.

* iii. Intellectual Property: Do not infringe upon the copyrights, trademarks, trade secrets, or other intellectual property rights of any person or entity. Do not reproduce, publish, or disseminate software, audio recordings, video recordings, photographs, articles, or other works of authorship without the written permission of the copyright holder.

* iv. Hacking, Viruses, and Network Attacks: Do not access any computer, computer network, or communications system without authorization, including the computers used to provide the Credify Service. Do not attempt to penetrate or disable any security system. Do not intentionally distribute a computer virus, launch a denial of service attack, or in any other way attempt to interfere with the functioning of any computer, communications system, or website. Do not attempt to access or otherwise interfere with the accounts of other users of the Service.

* v.  Spam: Do not send bulk, unsolicited emails (“Spam”) or sell or market any product or service advertised by or connected with Spam. Do not facilitate or cooperate in the dissemination of Spam in any way. Do not violate the CAN-SPAM Act of 2003 or any other similar laws or laws designed to prevent or control unsolicited emails.

* vi. Fraud: Do not issue fraudulent offers to sell or buy products, services, or investments. Do not mislead anyone about the details or nature of a commercial transaction. Do not commit fraud in any other way.

* vii. Violations of Law: Do not violate any Law.

### B. Consequences of Violation
Violation of this Acceptable Use Policy, or the Agreement at large, may lead to suspension or termination of your account(s), notification of illegal or improper activities to regulatory and/or governmental bodies, or legal action against you. In addition, you may be required to pay for the costs of investigation and remedial action related to violations of this Policy or this Agreement. To the extent your conduct constitutes a violation or suspected violation of any law, you agree and consent that Credify may provide any and all relevant information to law enforcement personnel for purposes of investigating a violation or alleged violation of law. Credify reserves the right to take any other remedial action it sees fit.

### C. Reporting Unacceptable Use
Credify requests that anyone with information about a violation of this Acceptable Use Policy report it via email to hello@getcredify.com. Please provide the date and time (with time zone) of the violation and any identifying information regarding the violator, including the email or internet protocol (IP) address, along with any other available information that can identify the violator, as well as details of the violation.

### D. Revision of Acceptable Use Policy
Credify may, from time to time, update this Acceptable Use Policy by posting a new version on this page and sending the Customer or User written notification (which may be in a digital or electronic format) of the change. The new version will become effective on the date of such notification.

## VII. Limitations, Disclaimers, and Wavier of Warranties:

* A. WE ARE NOT YOUR AGENT, NOR ARE WE AN AGENT OF ANY USER. WE ARE A TECHNOLOGY COMPANY OFFERING THE USE OF OUR SOFTWARE AND CLOUD-BASED PLATFORM FOR OTHERS TO CONDUCT BUSINESS OR FIND OTHERS OFFERING OR SOLICITING LOANS OR OTHER FINANCIAL OFFERINGS. PARTIES UTILIZING OUR SERVICES TO INTERACT OR ENGAGE IN BUSINESS WITH OTHER PARTIES DO SO AT THEIR OWN RISK AND OF THEIR OWN ACCORD, AND ARE SOLELY RESPONSIBLE FOR RESEARCHING, VALIDATING, AND UNDERSTANDING THOSE OTHER PARTIES, THE SPECIFIC FINANCIAL POSTURES OF THOSE PARTIES, AND ANY CONTRACTUAL DUTIES OR OBLIGATIONS CONTEMPLATED, SOLICITED, OFFERED, ACCEPTED, OR OTHERWISE UNDERTAKEN WITH THOSE PERSONS OR ENTITIES. WE DO NOT ENDORSE, RECOMMEND, OR MAKE OTHER REPRESENTATIONS REGARDING THE USERS OF OUR SERVICES TO OFFER OR SOLICIT LOANS OR EXTENSIONS OF CREDIT. USERS ARE SOLELY RESPONSIBLE FOR RELATIONSHIPS, LOANS, OFFERINGS, REPRESENTATIONS, AND/OR CONTRACTUAL OBLIGATIONS OFFERED OR SOLICITED THROUGH OUR SERVICE. BY USING OUR SERVICE, YOU AGREE THAT NEITHER WE NOR OUR REPRESENTATIVES ARE LIABLE OR RESPONSIBLE IN ANY WAY FOR ANY DAMAGES, COSTS, FEES, FINES, ASSESSMENTS, OR OTHER HARMS RELATED TO OR ARISING FROM YOUR RELATIONSHIP WITH US OR YOUR RELATIONSHIP WITH ANY USER UNDERTAKEN THROUGH OR IN CONNECTION WITH OUR SERVICES, AND YOU AGREE TO THE INDEMNIFICATION PROVISIONS PROVIDED ELSEWHERE IN THIS AGREEMENT.

* B. Customer hereby represents and warrants (1) that it is compliant with all Laws applicable to mortgage brokers and the services it is offering or promoting; (2) that Customer has obtained any and all necessary licenses or registrations, and has satisfied all other regulatory or other obligations related to its offered lending or financial operations or services; and/or (3) that it has or will independently research any person or entity with whom it is connecting through the Credify services to verify and satisfy itself that the other person or entity has not misrepresented itself, has provided true, complete, and accurate information, or is otherwise compliant with any and all applicable rules, laws, or obligations. CUSTOMER UNDERSTANDS AND AGREES THAT CREDIFY, WHETHER VIA THE CREDIFY SERVICES OR OTHERWISE, IS NOT RESPONSIBLE FOR VERIFYING THIS INFORMATION. CUSTOMER FULLY ASSUMES ALL RESPONSIBILITY FOR, OR LIABILITY ARISING FROM, ANY  MISREPRESENTATIONS BY BORROWERS AND AGREES TO INDEMNIFY AND HOLD HARMLESS CREDIFY FOR ANY CLAIMS ARISING FROM, OR RELATED TO, ANY SUCH ISSUES.

* C. Credify warrants that the Credify services will perform substantially and materially in accordance with the functions described in the user documentation under normal use and circumstances by Authorized Users of the Services, in accordance with this documentation and the specific provisions of the applicable package selected and purchased by Customer. UNDER NO CIRCUMSTANCES, HOWEVER, IS CREDIFY LIABLE FOR ANY DEFECTS OR ERRORS IN THE CREDIFY SERVICES OR THE PROGRAMMING ON WHICH IT IS BASED. IF THERE IS A MATERIAL BREACH OF THE ABOVE WARRANTY, CREDIFY’S ENTIRE LIABILITY AND CUSTOMER’S ENTIRE REMEDY SHALL BE, AT CREDIFY’S OPTION, TO (I) MODIFY THE SERVICES TO CONFORM TO THE DOCUMENTATION; (II) PROVIDE A REASONABLE WORKAROUND SOLUTION WHICH WILL REASONABLY MEET CUSTOMER’S REQUIREMENTS; OR (III) IF NEITHER OF THE FOREGOING IS COMMERCIALLY REASONABLE, TERMINATE THIS AGREEMENT WITH NO FURTHER LIABILITY TO CREDIFY. THESE REMEDIES ARE CUSTOMER’S SOLE AND EXCLUSIVE REMEDIES FOR A CLAIM OF BREACH OF WARRANTY UNDER THIS AGREEMENT. EXCEPT FOR INDEMNIFICATION OBLIGATIONS THAT MAY ARISE HEREUNDER, CUSTOMER EXPRESSLY AGREES THAT THE USE OF THE CREDIFY SERVICES IS A T THE CUSTOMER’S SOLE RISK.

* D. Customer is solely responsible for any interactions with advertisers, vendors, third party service providers and other users (collectively, “Third Parties”). We are not responsible for the activities, omissions, or other conduct, whether online or offline, of any Third Party. We are not responsible for any incorrect, inaccurate, or unlawful content posted through the Service by Third Parties. Under no circumstances shall we be responsible for any loss or damage resulting from the use of Credify by Third Parties or from any content posted on Credify or transmitted to users by Third Parties, or any interactions with Third Parties, whether online or offline.

## VIII. Choice of Law and Dispute Resolution:

### A. Choice of Law
This Agreement is governed by the laws of the State of New Jersey without regard to any conflict of law rules.

### B. Venue
Subject to the Arbitration provisions set forth below, the parties hereby agree that any action or claim brought in connection with, or based in any way upon, this Agreement, or any action or claim brought by Customer against Credify, regardless of whether that claim sounds in contract, tort, or otherwise, including any decision to enforce an arbitration award, will be brought only in the state of New Jersey, County of Hudson, and that any attempt for such a claim to be brought in another location shall be deemed null and void. The Parties hereby manifest their intent to submit to the jurisdiction of the State of New Jersey for all claims falling within the scope of this provision.

### C. Arbitration
The parties agree that any controversy, claim, or action arising out of or relating to this Agreement, or the breach thereof, shall be settled by final and binding arbitration administered by the American Arbitration Association in accordance with its Commercial Arbitration Rules (including the Optional Rules for Emergency Measures of Protection). The arbitration shall take place in Hudson County, New Jersey before a single arbitrator. Judgment on any award rendered by the arbitrator may be entered in any court having jurisdiction thereof.

## IX. Limitation on Time to File Claims:
YOU AGREE THAT ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THIS AGREEMENT OR YOUR USE OF THE CREDIFY SERVICES MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS HEREBY AGREED TO BE WAIVED AND PERMANENTLY BARRED.

## X. General Indemnification:

* A. Direct Indemnification: You agree to defend and indemnify Credify and its Representatives, officers, owners, directors, shareholders, parents, subsidiaries, agents, affiliates, successors, and assigns for any Losses, claims, expenses, or costs of any kind, including reasonable attorney fees, arising from (1) your use of the Credify Services, (2) your failure to comply with the Credify Terms of Service, (3) negligence, actions, or omissions, (4) violation or alleged violation of any law, or (5) violation or alleged violation of the rights of any third-party.

* B. Third-Party Claim Indemnification: You agree to defend and indemnify Credify and its Representatives, officers, owners, directors, shareholders, parents, subsidiaries, agents, affiliates, successors, and assigns against any third-party claims, suit, or proceeding, or for any Losses, expenses, or costs of any kind, including reasonable attorney fees, arising out of or related to Customer’s alleged or actual use of, misuse of, or failure to use the Credify Services, including without limitation (1) claims by your customers, users, subscribers, and/or employees, and (2) claims related to unauthorized disclosure or exposure of personally identifiable information or other private information.

* C. Indemnified Claims: Indemnified claims include, without limitation, claims arising out of or related to Credify’s alleged negligence. Credify will control the defense of any indemnified claim, including appeals, negotiations, and any settlement or compromise thereof, provided that Customer will have the right, not to be exercised unreasonably, to reject any settlement or compromise that requires that it admit wrongdoing or liability or subjects it to any ongoing affirmative obligations. Customer’s obligations set forth above include retention and payment of attorneys and payment of court costs, as well as settlement at Customer’s expense and payment of judgments.

## XI. Force Majeure:

* A. In no event will Credify or its Representatives be liable or responsible, or be deemed to have defaulted under or breached this Agreement, for any failure or delay in fulfilling or performing any term of this Agreement (except for any payment obligation) when and to the extent such failure or delay is caused by any circumstances beyond Credify’s reasonable control (a “Force Majeure Event”), including acts of God, flood, fire, earthquake, explosion, war, terrorism, invasion, riot or other civil unrest, embargoes or blockades in effect on or after the date of this Agreement, national or regional emergency, strikes, labor stoppages or slowdowns or other industrial disturbances, passage of Law or any action taken by a governmental or public authority, including imposing an embargo, export or import restriction, quota or other restriction or prohibition or any complete or partial government shutdown, or other national or regional shortage of adequate power or telecommunications or transportation. Either party may terminate this Agreement if a Force Majeure Event continues substantially uninterrupted for a period of 30 days or more.

* B. In the event of any failure or delay caused by a Force Majeure Event, Credify shall give prompt written notice to Customer stating the period of time the occurrence is expected to continue and use commercially reasonable efforts to end the failure or delay and minimize the effects of such Force Majeure Event.

## XII. Cookie Policy:
Credify uses cookies, small files stored on your computer, to provide services and track user activities on its website and within the Credify product. Cookies may help improve the user experience, for example, by allowing users to maintain a persistent log-on and to remember users when they return to the Service. If you do not wish for Cookies to be used, you should clear the cookies from your computer and configure the Customer Systems to prevent cookies in the future.

## XIII. Entire Agreement:
This document, which may include the Privacy Policy and Terms of Use posted and maintained on the Credify website as updated from time to time (incorporated herein by reference), and representations made regarding the specific package selected and purchased by Customer, is the entire agreement between Credify and Customer. No other agreements, statements, representations, assertions, claims, or promises made prior to, or contemporaneous with, this Agreement that alter, contradict, or otherwise purport to influence this Agreement have any effect and are expressly excluded from the Agreement between Credify and Customer.

## XIV. Severability:
If any portion, provision, or term of this Agreement is determined to be unenforceable, illegal, or invalid, that portion, provision, or term will be excluded from this Agreement to the extent it is unenforceable, illegal, or invalid. The remainder of this Agreement shall continue and remain in effect provided that the exclusion of any term does not materially change or frustrate (1) the intent of the Parties or (2) the economic substance of this Agreement between the Parties.

## CONTACT INFORMATION

We welcome your comments or questions about these terms.

You may also contact us at our address:

Credify Corporation
235 Grand Street #3603
Jersey City, NJ 07302
Email: [hello@getcredify.com](mailto:hello@getcredify.com)

## Texas Consumers

**TEXAS MORTGAGE COMPANY DISCLOSURE**

We will submit your loan application to a participating lender or we will make your loan ourselves. In connection with this mortgage loan, we are acting as an independent contractor and not as your agent.
  
We will be compensated in compliance with Section 1026.36(d) of Regulation Z (12 C.F.R.§ 1026.36(d)) (if applicable).

Pursuant to the requirements of Section 157.0021 of the Mortgage Banker Registration and Residential Mortgage Loan Originator License Act, Chapter 157, Texas Finance Code, you are hereby notified of the following:

CONSUMERS WISHING TO FILE A COMPLAINT AGAINST A MORTGAGE BANKER OR A LICENSED MORTGAGE BANKER RESIDENTIAL MORTGAGE LOAN ORIGINATOR SHOULD COMPLETE AND SEND A COMPLAINT FORM TO THE TEXAS DEPARTMENT OF SAVINGS AND MORTGAGE LENDING, 2601 NORTH LAMAR, SUITE 201, AUSTIN, TEXAS 78705. COMPLAINT FORMS AND INSTRUCTIONS MAY BE OBTAINED FROM THE DEPARTMENT’S WEBSITE AT [WWW.SML.TEXAS.GOV](https://www.sml.texas.gov). A TOLL-FREE CONSUMER HOTLINE IS AVAILABLE AT 1-877-276-5550. 

THE DEPARTMENT MAINTAINS A RECOVERY FUND TO MAKE PAYMENTS OF CERTAIN ACTUAL OUT OF POCKET DAMAGES SUSTAINED BY BORROWERS CAUSED BY ACTS OF LICENSED MORTGAGE BANKER RESIDENTIAL MORTGAGE LOAN ORIGINATORS. A WRITTEN APPLICATION FOR REIMBURSEMENT FROM THE RECOVERY FUND MUST BE FILED WITH AND INVESTIGATED BY THE DEPARTMENT PRIOR TO THE PAYMENT OF A CLAIM. FOR MORE INFORMATION ABOUT THE RECOVERY FUND, PLEASE CONSULT THE DEPARTMENT’S WEBSITE AT [WWW.SML.TEXAS.GOV](https://www.sml.texas.gov).  
`

export default MarkdownContent
