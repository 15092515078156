const MarkdownContent =
`
Credify is a platform that uses smart integrations and technology to streamline your mortgage application process.

**We don't offer you loans or financing directly.**

Rather, we make it easy to retrieve, centralise and manage your important documents and information to then digitally fill out mortgage applications, using the newly available digital standards for the Uniform Residential Loan Application (Form 1003) or so-called URLA.

Once applications are submitted, we let brokers and financial institutions provide offers to you. When an offer is submitted, you have a chance to review all the offer's pertinent details. At any time another lender can submit an offer than must MATCH or BEAT any existing offer.

So, they have to battle to give you the best offer for your specific scenario, and you won't be flooded with offers that are all the same or worse -- like you may experience with traditional mortgage lead generators.

Once you accept an offer, we deliver a package of secure information and documents to the lender, who will then contact you to finalise the application. We do not release this information to a lender until you accept their offer -- so lenders are only able to see the bare minimum information they need to provide a pre-approved offer. This means no spam, no contact by brokers, nothing, until you accept a particular offer.

Once you accept an offer, Credify charges a fee to the lender, and you get to continue with your financing application with the lender.

If you ever want to refinance, you can do it through Credify as well, where all your information is already available, making subsequent applications even easier! You can even schedule these well ahead of time to avoid any rush and give maximum time for lenders to battle for your decision.
`

export default MarkdownContent
