const MarkdownContent =
    `
This privacy policy (“Policy”) describes how Credify Corporation and its related companies (“Company”) collect, use and share personal information of consumer users of this website, getcredify.com (the “Site”). This Policy also applies to any of our other websites that post this Policy. This Policy does not apply to websites that post different statements.

## WHAT WE COLLECT
We get information about you in a range of ways.

Information You Give Us. We collect your‎ name, postal address, email address, phone number, email address, username, password, demographic information (such as your gender and occupation) as well as other information related to your financial situation such as your Social Security number, credit score and credit history, account balances and transaction history, income and employment information.

Information We Get From Others. We may get information about you from other sources such as credit bureaus, third party identity verification services, your financial institution or property information from data aggregators. We may add this to information we get from the Site.

Information Automatically Collected. We automatically log information about you and your computer. For example, when visiting our Site, we log your computer operating system type, browser type, browser language, the website you visited before browsing to our Site, pages you viewed, how long you spent on a page, access times and information about your use of and actions on our Site.

Cookies. We may log information using "cookies." Cookies are small data files stored on your hard drive by a website. We may use both session Cookies (which expire once you close your web browser) and persistent Cookies (which stay on your computer until you delete them) to provide you with a more personal and interactive experience on our Site, and display targeted promotional content.  This type of information is collected to make the Site more useful to you and to tailor the experience with us to meet your special interests and needs.

## USE OF PERSONAL INFORMATION
We use your personal information as follows:

* We use your personal information to fulfill or meet the reason you provided the information. For example, if you share your name and contact information to request a mortgage loan from a broker or lender, we will use that personal information to facilitate a response to your inquiry.

* We use your personal information to operate, maintain, and improve our sites, products, and services.

* We use your personal information to respond to comments and questions and provide customer service.

* We use your personal information to send information including confirmations, invoices, technical notices, updates, security alerts, and support and administrative messages.

* We use your personal information to communicate about promotions, upcoming events, and other news about products and services offered by us and our selected partners.

* We use your personal information to link or combine user information with other personal information.

* We use your personal information to protect, investigate, and deter against fraudulent, unauthorized, or illegal activity.

* We use your personal information to provide and deliver products and services customers request.

## THIRD-PARTY USE OF COOKIES AND OTHER TRACKING TECHNOLOGIES

Some content or applications, including advertisements, on the Website are served by third-parties, including advertisers, ad networks and servers, content providers, and application providers. These third parties may use cookies alone or in conjunction with other tracking technologies to collect information about you when you use our website. The information they collect may be associated with your browsing information or they may collect information about your online activities over time and across different websites and other online services. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content. We do not control these third parties' tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly. For information about how you can opt out of receiving targeted advertising from many providers, see “Information Choices and Changes” below.

## SHARING OF PERSONAL INFORMATION

We may share personal information as follows:

* We may share personal information with third party service providers with whom we share such information as part of providing products and services, completing transactions, supporting our everyday operations, or business management and development. For example, we may share your personal and financial information to prospective brokers and lenders to facilitate the processing of your loan request.

* We may also disclose personal information to credit bureaus and similar organizations, and may exchange personal information with reference sources and clearinghouse services for purposes of fraud protection and credit reporting and verification.

* We may share personal information with your consent. For example, you may let us share personal information with others for their own marketing uses. Those uses will be subject to their privacy policies.

* We may share personal information when we do a business deal, or negotiate a business deal, involving the sale or transfer of all or a part of our business or assets. These deals can include any merger, financing, acquisition, or bankruptcy transaction or proceeding.

    * We may share personal information for legal, protection, and safety purposes.

    * We may share information to comply with laws.

    * We may share information to respond to lawful requests and legal processes.

    * We may share information to protect the rights and property of Credify Corporation, our agents, customers, and others. This includes enforcing our agreements, policies, and terms of use.

    * We may share information in an emergency. This includes protecting the safety of our employees and agents, our customers, or any person.

* We may share information with those who need it to do work for us.

We may also share aggregated and/or anonymized data with others for their own uses.

## INFORMATION CHOICES AND CHANGES

Our marketing emails tell you how to “opt-out.” If you opt out, we may still send you non-marketing emails. Non-marketing emails include emails about your accounts and our business dealings with you.

You may send requests about personal information to our Contact Information below. You can request to change contact choices, opt-out of our sharing with others, and update your personal information.

You can typically remove and reject cookies from our Site with your browser settings. Many browsers are set to accept cookies until you change your settings. If you remove or reject our cookies, it could affect how our Site works for you.

Targeted Advertising. If you do not want us to use information that we collect or that you provide to us to deliver advertisements according to our advertisers' target-audience preferences, you can opt-out by changing your browser settings.

Disclosure of Your Information for Third-Party Advertising. If you do not want us to share your personal information with unaffiliated or non-agent third parties for promotional purposes, you can opt-out by sending us an email with your request to hello@getcredify.com.

## CONTACT INFORMATION

We welcome your comments or questions about this privacy policy.

You may also contact us at our address:

Credify Corporation
235 Grand Street #3603
Jersey City, NJ 07302
Email: hello@getcredify.com

## CHANGES TO THIS PRIVACY POLICY

We may change this privacy policy. If we make any changes, we will change the Last Updated date above.
`

export default MarkdownContent
