import React from 'react';
import { ThemeProvider } from 'theme-ui';
import theme from '../theme';
import Layout from '../components/layout';
import Partnerships from '../sections/partnerships/page';

const PartnershipsPage = () => {
    return (
        <ThemeProvider theme={theme}>
            <Layout>
                <Partnerships />
            </Layout>
        </ThemeProvider>
    )
}

export default PartnershipsPage;