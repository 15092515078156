export const homeMenu = [
  {
    path: `https://approve${process.env.REACT_APP_NODE_ENV === 'staging' ? '-staging4nf' : ''}.getcredify.com`,
    label: 'Turbo Approve',
  },
  {
    path: '/home',
    label: 'Home',
  },
  {
    path: '/borrowers',
    label: 'For Borrowers',
  },
  {
    path: '/brokers',
    label: 'For Brokers',
  },
  // {
  //   path: '/about-us',
  //   label: 'About Us',
  // },
  {
    path: '/calculators',
    label: 'Calculators',
  },
  {
    path: 'tel:+18005576196',
    label: 'Call Us',
    icon: 'call'
  },
];

export const brokerMenu = [
  {
    path: 'tel:+18005576196',
    label: 'Call Us',
    icon: 'call'
  },
  {
    path: '/home',
    label: 'Home',
  },
  {
    path: '#features',
    label: 'Features',
  },
  {
    path: '#how-it-works',
    label: 'How It Works',
  },
  {
    path: '#faq',
    label: 'FAQ',
  },
  // {
  //   path: '/about-us',
  //   label: 'About Us',
  // },
];

export const borrowerMenu = [
  {
    path: `https://approve${process.env.REACT_APP_NODE_ENV === 'staging' ? '-staging4nf' : ''}.getcredify.com`,
    label: 'Turbo Approve',
  },
  {
    path: '/home',
    label: 'Home',
  },
  {
    path: '#how-it-works',
    label: 'How It Works',
  },
  {
    path: '#faq',
    label: 'FAQ',
  },
  // {
  //   path: '/about-us',
  //   label: 'About Us',
  // },
  {
    path: 'tel:+18005576196',
    label: 'Call Us',
    icon: 'call'
  },
];
