import React from 'react';
import { ThemeProvider } from 'theme-ui';
import theme from '../theme';
import Layout from '../components/layout';
import WebsiteTermsOfUse from '../sections/terms-of-use/page';

const WebsiteTermsOfUsePage = () => {
    return (
        <ThemeProvider theme={theme}>
            <Layout>
                <WebsiteTermsOfUse />
            </Layout>
        </ThemeProvider>
    )
}

export default WebsiteTermsOfUsePage;
