import { Image } from 'theme-ui';
import { Link } from '../components/link';
import credify from '../assets/images/credify.svg';
import credifyWhite from '../assets/images/credify-white.svg';

export default function Logo({ isWhite }) {
  return (
    <Link
      path="/"
      style={{
        variant: 'links.logo'
      }}
      sx={styles.logo}
    >
      <Image src={isWhite ? credifyWhite : credify} alt="credify logo" />
    </Link>
  );
}

const styles = {
  logo: {
    img:{
      height: '45px',
      width: 'auto'
    }
  }
}
