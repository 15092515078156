import { Box, Text, Container, IconButton } from 'theme-ui';
import Logo from '../logo';
import { Link } from '../link';
import FooterWidget from '../footer-widget';
import { menuItems, footerNav } from './footer.data';
import fbIcon from './facebook.svg';
import linkedinIcon from './linkedin.svg';
import { rgba } from 'polished';

export default function Footer() {
  return (
    <Box as="footer" variant="layout.footer">
      <Container>
        <Box sx={styles.footerTopInner}>
          {menuItems.map(({ id, title, items }) => (
            <FooterWidget key={id} title={title} items={items} />
          ))}
        </Box>
      </Container>
      <Container>
        <Box sx={styles.companyInfo}>
          <Text as="span">
            NMLS ID# 2246018 | <Link path="/disclosures-licensing">Licenses</Link> | <Link path="https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/2246018">NMLS Consumer Access</Link>
          </Text>
          <Text as="span">
            Credify Corporation 235 Grand Street, #3603, Jersey City, NJ 07302
          </Text>
          <Text as="span">
            <Link path="tel:+18005576196">+1 800 557 6196</Link>
          </Text>
          <div>
            <IconButton aria-label='Facebook' onClick={() => window.open('https://www.facebook.com/getcredify', '_blank')}>
              <img src={fbIcon} alt="Facebook" />
            </IconButton>
            <IconButton aria-label='LinkedIn' onClick={() => window.open('https://www.linkedin.com/company/getcredify', '_blank')}>
              <img src={linkedinIcon} alt="LinkedIn" />
            </IconButton>
          </div>
        </Box>
        <Box sx={styles.companyInfo}>
        </Box>
      </Container>
      <Container>
        <Box sx={styles.footerInner}>
          <Box sx={styles.copyright}>
            <Logo />
          </Box>
          <Box sx={styles.copyright}>
            <Text as="span">
              © Credify Corporation {new Date().getFullYear()}
            </Text>
          </Box>
          <Box as="ul" sx={styles.footerNav}>
            {footerNav.map(({ path, label, onClick }, i) => (
              <li key={i}>
                {path && <Link path={path} key={i} label={label} variant="footer" />}
                {onClick && <Link onClick={onClick} key={i} label={label} variant="footer" />}
              </li>
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

const styles = {
  footerTopInner: {
    gap: [50, null, null, null, 17, 50],
    mb: [50],
    display: ['grid'],
    gridTemplateColumns: [
      'repeat(2, 1fr)',
      null,
      null,
      'repeat(3, 1fr)',
      'repeat(4, 1fr)',
    ],
  },
  footerInner: {
    borderTop: `1px solid #D9E0E7`,
    display: ['block', null, 'flex'],
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '35px 0 40px',
    '@media only screen and (max-width: 400px)': {
      pb: 10,
    },
  },
  copyright: {
    display: ['flex'],
    alignItems: 'center',
    flexDirection: ['column', null, null, null, 'row'],
    span: {
      fontSize: '14px',
      lineHeight: 1.29,
      color: rgba('#0F2137', 0.6),
      mt: ['18px', '18px', '7px'],
    },
  },
  companyInfo: {
    display: ['flex'],
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: ['column'],
    mt: ['30px'],
    span: {
      fontSize: '16px',
      lineHeight: 1.29,
      color: rgba('#0F2137', 0.6),
      mt: ['18px', '18px', '7px'],
    },
    a: {
      textDecoration: 'none',
      color: rgba('#0F2137', 0.6),
    },
    'a:hover': {
      textDecoration: 'none',
      color: 'primary'
    }
  },
  footerNav: {
    listStyle: 'none',
    margin: ['15px 0 0', '15px 0 0', '0'],
    padding: 0,
    display: ['flex'],
    flexWrap: ['wrap', null, null, 'unset'],
    justifyContent: ['center', null, 'flex-start'],
    'li + li': {
      ml: ['18px', '18px', '20px'],
      '@media only screen and (max-width: 400px)': {
        mb: '10px',
      },
    },
    a: {
      textDecoration: 'none',
      color: 'textSecondary'
    },
    'a:hover': {
      textDecoration: 'none',
      color: 'primary'
    }
  },
};
