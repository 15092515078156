import { Container, Box, Link } from 'theme-ui';
import SectionHeader from '../../components/section-heading';
import Accordion from '../../components/accordion/accordion';
const faqs = [
  {
    title: 'Who is Credify?',
    contents: (
      <div>
        We are a US-registered Fintech startup, backed by Silicon Valley Bank.
      </div>
    ),
  },
  {
    title: 'How many mortgages can I bid for each month?',
    contents: (
      <div>
        As many as you like.
      </div>
    ),
  },
  {
    title: 'What does it cost?',
    contents: (
      <div>
        A case costs 50bps and can be paid on completion, subject to a maximum pipeline of $2m in loans. For more active brokers, the 50bps can be paid on receipt of the case file after an offer has been chosen by a borrower.
      </div>
    ),
  },
  {
    title: 'Can the same broker offer the same mortgage from the same lender to the same borrower?',
    contents: (
      <div>
        No. Our system disallows this so you have to be fast.
      </div>
    ),
  },
  {
    title: 'Can I set alerts for potential clients I’d like to deal with so I can be first to bid?',
    contents: (
      <div>
        Yes... we have instant alerts by email and SMS if a deal you have pre-defined comes onto the platform. You can also create saved searches and use the API to be notified of anything you want instantly.
      </div>
    ),
  },
  {
    title: 'Do I get a refund if the deal doesn’t go through?',
    contents: (
      <div>
        Yes! We offer a 3 day refund window if the deal materially changes.
      </div>
    ),
  },
  {
    title: 'Are there any other fees?',
    contents: (
      <div>
        Credify is free for the first 6 months for all brokers and $200 a month thereafter. Brokers can cancel at any time.
      </div>
    ),
  },
  {
    title: 'If I sell an insurance policy or other financial product to the borrower, do I owe Credify any money?',
    contents: (
      <div>
        No. You keep all the commission from products you upsell.
      </div>
    ),
  },
  {
    title: 'How does the automation suite work?',
    contents: (
      <div>
        However you like! It's an API with a webhook events system, so you can design your own custom logic to suit your needs. If you don’t have the tech expertise in house, you can hire our development team who will create your bespoke solution. Click <a style={{ textDecoration: 'none', color: '#008600' }} href='mailto:hello@getcredify.com'>here</a> for more information.
      </div>
    ),
  },
  {
    title: 'I’m a specialist broker. Will Credify work for me?',
    contents: (
      <div>
        Credify was built for you! Whatever your specialism, you can find it on Credify, including commercial, buy to rent, sub prime, super prime, boats, second homes, literally anything you can think of...except submarines. We don’t do submarines.
      </div>
    ),
  },
  {
    title: 'How many cases are in my State?',
    contents: (
      <div>
        Log in and see for yourself. It’s free to browse. You’ll need your NMLS number and we’ll automatically filter the order book to your licensed State(s).
      </div>
    ),
  },
  {
    title: 'My question isn’t answered here and it’s really important',
    contents: (
      <div>
        Contact support <a style={{ textDecoration: 'none', color: '#008600' }} href='mailto:hello@getcredify.com'>here</a>. We aim to answer within one business day.
      </div>
    ),
  },
];
export default function Faq() {
  return (
    <Box as="section" id="faq" variant="section.faq">
      <Container>
        <SectionHeader
          title="Do you have any questions?"
          description="Contact us and receive a response in 24 hour or less."
        />
        <Box
          sx={{
            display: 'flex',
            width: ['100%', null, null, '650px', '745px'],
            flexDirection: 'column',
            mx: 'auto',
            my: -4,
          }}
        >
          <Accordion items={faqs} />
        </Box>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Link variant="primary" sx={styles.button} href={`https://app.${process.env.REACT_APP_NODE_ENV === 'staging' ? 'staging.' : ''}getcredify.com/institution/sign-up`}>
            Signup Now
          </Link>
        </div>
      </Container>
    </Box>
  );
}

const styles = {
  button: {
    display: ['flex', 'flex'],
    justifyContent: "center",
    width: ['100%'],
    maxWidth: "500px",
    mt: 50,
    mb: 0,
    borderRadius: 5,
    color: "#FFF",
    backgroundColor: "primary",
    lineHeight: '50px',
    height: '50px',
    fontSize: ['15px', '20px', '20px', '15px', '18px', '20px'],
    fontWeight: '500',
    textDecoration: "none",
    ":hover": {
      backgroundColor: "dark"
    }
  },
};
