const MarkdownContent =
`
Last updated February 04, 2022

## Disclosures

### General

Credify Corporation is based in New Jersey, USA with the following address and contact details:

Credify Corporation, 235 Grand Street #3603, Jersey City, NJ 07302

Email: [hello@getcredify.com](mailto:hello@getcredify.com)

Phone: +1 800 557 6196

Any reference in this Licenses and Disclosures to "we" or "us" or similar words shall refer to Credify Corporation unless otherwise indicated, all such entities referred to as "Credify" or "GetCredify".

Any reference in Licenses and Disclosures to "you" or "your" means the person(s) using the Credify Websites (as defined in the [Terms of Use Agreement](/terms-of-use),

Credify is a software-as-a-service that streamlines mortgage and financing application processes and are required to be licensed as a Mortgage Broker or other state license designation.

Credify DOES NOT take mortgage applications, originate, service, make loans or credit decisions related to such loans, or issue commitments and lock-in agreements. Our services are only administrative insofar as we facilitate connection you with offers from licensed loan service providers.

Any loan inquiry you submit is NOT an application for credit. Rather, it is an inquiry to be matched with Lenders to receive condition loan offers from Lenders. You may have to complete an application with a Lender before they will extend an unconditional offer. A Lender you select may require you to pay an application or other fee to cover the costs of an appraisal, credit report or other items.

The Lender, not Credify, will determine the amount of any such fee and should provide information to you regarding the refundability of any such fee. Credify Corporation does not endorse or recommend the products of any particular Lender.

Except as otherwise provided for your state, Credify Corporation is not an agent of you or any Lender. You should rely on your own judgment in deciding which available loan product, terms and Lender that best suits your personal financial requirements. The Lender is solely responsible for its services to you, and you agree that Credify Corporation shall not be liable for any damages or costs of any type arising out of, or in any way connected with, your use of such services.

You understand that Lenders may keep your loan request information and any other information provided by Credify Corporation or received by them in the processing of your loan request, whether or not you are qualified for a loan with them or if you make a loan with them. In addition, Credify Corporation may receive personally identifiable information regarding the disposition of your loan from the Lender in order to fulfill its regulatory requirements as well as for internal business purposes. You agree to notify any particular Lender directly if you no longer want to receive communications.

### Fees

Credify does not charge you any fee for its services. Credify receives its compensation from the Lenders or Providers on acceptance of a credit offer. Such fees may be refunded within our refund window in the event of cancellation of a  credit application by you or the loan provider.

### Credit

Any loan request you submit is NOT an application for credit. Rather, it is a request to be matched with Lenders to receive conditional loan offers from Lenders. You may have to complete a full application with a Lender before they will extend an unconditional loan offer. A Lender you select may require you to pay an application or other fee to cover the costs of an appraisal, credit report as well as for other items. The Lender will determine the amount of any such fee(s) and should provide information to you regarding the refundability of any such fee(s).

### Federal Disclosures

Any request will be forwarded to Lenders, Brokers, Dealers, or Partners (depending on product selected) and these Partners may make certain disclosures to you.

These disclosures, which are required by the Truth in Lending Act (TILA), the Real Estate Settlement Procedures Act (RESPA), and certain other federal and state laws, include details of your cost of credit; good faith estimates of your settlement costs (GFE) on August 1, 2015 the GFE will be called the Integrated Loan Disclosure or Loan Estimate and will be delivered to you by the Lender you select to proceed with for your loan needs; HUD mandated booklets “Guide to Settlement Costs” and/or “When your Home is on the Line”; notices concerning the possibility that your mortgage loan servicing may be transferred; and your right to a copy of any appraisal, if any, performed on your home.

The Equal Credit Opportunity Act (ECOA) prohibits creditors from discriminating against credit applicants on the basis of race, color, religion, national origin, sex, marital status, age (provided the applicant has the capacity to enter into a binding contract), because all or part of the applicant’s income derives from any public assistance program, or because the applicant has in good faith exercised any right under the Consumer Credit Protection Act.

The agency that administers compliance with this law varies by Lender depending on its lending authority, but may include the Federal Trade Commission, Equal Credit Opportunity, Consumer Response Center, Washington, DC 20580 or at 1-877-FTC-HELP (1-877-382-4357; TDD 1-866-653-4261; or at www.ftc.gov.

PURSUANT TO CREDIFY'S [TERMS OF USE AGREEMENT](/terms-of-use) AND THE CONSENT FOR ELECTRONIC DISCLOSURES, THE DISCLOSURES AND CONSENTS REQUIRED UNDER CERTAIN FEDERAL AND/OR STATE LAWS ARE DEEMED TO BE PROVIDED, RECEIVED AND AGREED TO BY YOUR USE OF THE WEBSITES (AS DEFINED IN THE TERMS OF USE AGREEMENT).

## Licensing

Credify Corporation is licensed in various US states, as below.

### State Licenses

Credify Corporation's Nationwide Mortgage Licensing System (NMLS) Identification Number is 2246018.

You can access our NMLS consumer data [here.](https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/2246018)

#### Texas

Credify is currently licensed in Texas with NMLS ID #2246018

## Other Notes

We advise you to print and retain a copy of this information and agreement for your records.
`

export default MarkdownContent
