import { Box, Container } from 'theme-ui';
import theme from '../../theme';
import SectionHeading from '../../components/section-heading';
import Feature from '../../components/cards/feature';
import n1 from '../../assets/images/icons/number-1.png';
import n2 from '../../assets/images/icons/number-2.png';
import n3 from '../../assets/images/icons/number-3.png';
// import dart from '../../assets/images/icons/dart.png';
// import award from '../../assets/images/icons/award.png';

const data = [
    {
        id: 1,
        icon: n1,
        title: 'Answer some questions',
        description: 'Answer some questions on the phone or online, with live chat help from our experts to build out your mortgage application in record time.',
    },
    {
        id: 2,
        icon: n2,
        title: 'Get pre-approved quickly',
        description: 'Often in minutes or even instantly! Lenders compete to offer you the best rate in an open marketplace, so you get pre-approved quickly and can start home shopping immediately.',
    },
    {
        id: 3,
        icon: n3,
        title: 'Choose the best rate for you',
        description: `Choose the offer that best fits your preferences with one click. It's that simple.`,
    }
];



const BorrowerHowItWorks = (props) => {
let boxStyle = props.box === 'alternatingBox' ? theme.styles.alternatingBox : theme.styles.sectionBox;
    return (
        <Box as="section" id="how-it-works" variant="section.features" sx={boxStyle}>
            <Container>
                <SectionHeading
                    className="featuresHeading"
                    title="How it Works"
                    description=""
                />
                <Box sx={styles.features}>
                    {data?.map((item) => (
                        <Feature key={item.id} data={item} />
                    ))}
                </Box>
            </Container>
        </Box>
    );
};

export default BorrowerHowItWorks;

const styles = {
    heading: {
        marginBottom: [60, 60, 60, 80],
    },
    features: {
        gap: ['35px 60px', 60, 60, 40, 30, 60],
        display: ['grid', 'grid'],
        gridTemplateColumns: [
            'repeat(1, 1fr)',
            'repeat(1, 1fr)',
            'repeat(1, 1fr)',
            'repeat(3, 1fr)',
        ],
    },
    extraFeature: {
        marginTop: '100px'
    }
};
