import { useLocation } from 'react-router-dom'
import { Box, Container, MenuButton, Flex, Button, Link } from 'theme-ui';
import { useState } from 'react';
import { GrClose } from 'react-icons/gr';
import Sticky from 'react-stickynode';
import Logo from '../logo';
import { NavLink } from '../link';
import { homeMenu, brokerMenu, borrowerMenu } from './header.data';

export default function Header() {
  let menuItems

  switch (useLocation().pathname) {
    case '/brokers':
      menuItems = brokerMenu;
      break;
    case '/borrowers':
      menuItems = borrowerMenu;
      break;
    default:
      menuItems = homeMenu;
  }

  const [mobileMenu, setMobileMenu] = useState(false);

  const openMobileMenu = () => {
    setMobileMenu(true);
  };

  const closeMobileMenu = () => {
    setMobileMenu(false);
  };

  return (
    <Box sx={styles.headerWrapper}>
      <Sticky enabled={true} top={0} activeClass="is-sticky" innerZ={10}>
        <Box
          as="header"
          variant="layout.header"
          className={mobileMenu ? 'is-mobile-menu' : ''}
        >
          <Container>
            <Box sx={styles.headerInner}>
              <Logo isWhite={mobileMenu} />

              <Flex
                as="nav"
                sx={styles.navbar}
                className={mobileMenu ? 'navbar active' : 'navbar'}
              >
                <Box
                  as="ul"
                  sx={styles.navList}
                  className={mobileMenu ? 'active' : ''}
                >
                  {menuItems.map(({ path, label, icon }, i) => (
                    <li key={i}>
                      <NavLink
                        path={path}
                        label={label}
                        // eslint-disable-next-line jsx-a11y/aria-props
                        aria-icon={icon}
                        onClick={closeMobileMenu}
                      />
                    </li>
                  ))}
                  {mobileMenu ? (
                    <Link className='borrowerCta' data-lum="action" variant="primary" sx={styles.button} href={`https://approve${process.env.REACT_APP_NODE_ENV === 'staging' ? '-staging4nf' : ''}.getcredify.com`}>
                      Get Turbo Approved Now
                    </Link>
                  ) : (
                    ''
                  )}
                </Box>
              </Flex>

              {mobileMenu ? (
                <Button variant="text" sx={styles.closeButton}>
                  <GrClose
                    onClick={closeMobileMenu}
                    color="white"
                    size="20px"
                  />
                </Button>
              ) : (
                <MenuButton aria-label="Toggle Menu" onClick={openMobileMenu} />
              )}
            </Box>
          </Container>
        </Box>
      </Sticky>
    </Box>
  );
}

const styles = {
  headerWrapper: {
    backgroundColor: 'transparent',
    transition: '0.3s ease-in-out 0s',
    '.is-sticky': {
      header: {
        backgroundColor: '#fff',
        boxShadow: '0 6px 13px rgba(38, 78, 118, 0.1)',
        py: [12],
        '&.is-mobile-menu': {
          backgroundColor: 'text',
        },
      },
    },
  },
  headerInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media only screen and (max-width: 768px)': {
      '.navbar': {
        position: 'absolute',
        top: '100%',
        backgroundColor: 'text',
        width: '100%',
        left: 0,
        p: '20px 30px',
        display: 'block',
        boxShadow: '0 6px 13px rgba(38,78,118,0.1)',
        opacity: 0,
        visibility: 'hidden',
        minHeight: 'calc(100vh - 77px)',
        transition: 'all 0.3s ease-in-out 0s',
        '&.active': {
          opacity: 1,
          visibility: 'visible',
        },
        ul: {
          display: 'block',
          'li + li': {
            marginTop: 5,
          },
          a: {
            color: 'white',
          },
        },
      },
    },
  },
  navbar: {
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'center',
  },
  navList: {
    display: ['flex'],
    listStyle: 'none',
    marginLeft: 'auto',
    p: 0,
    '.nav-item': {
      cursor: 'pointer',
      fontWeight: 400,
      padding: 0,
      margin: '0 20px',
      textDecoration: 'none',
      color: 'text'
    },
    '.nav-item:hover': {
      color: 'primary',
    }
  },
  explore: {
    display: ['block', 'block', 'block', 'block', 'none'],
    position: 'absolute',
    bottom: 40,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  closeButton: {
    height: '32px',
    padding: '4px',
    minHeight: 'auto',
    width: '32px',
    ml: '3px',
    path: {
      stroke: '#fff',
    },
  },
  mobileNavButton: {
    margin: '25px 20px'
  },
  button: {
    display: 'flex',
    justifyContent: "center",
    width: '100%',
    mt: 75,
    borderRadius: 5,
    color: "#FFF",
    backgroundColor: "primary",
    lineHeight: '50px',
    height: '50px',
    fontSize: '16px',
    fontWeight: '300',
    textDecoration: "none",
  },
};
