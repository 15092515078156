import { Box, Container, Heading, Flex } from 'theme-ui';
import ReactMarkdown from 'react-markdown'
import MarkdownContent from './markdown'
import rehypeRaw from 'rehype-raw'

// See https://remarkjs.github.io/react-markdown/

const Partnerships = () => {
  return (
    <Box id="partnerships" as="section" variant="section.page">
      <Container>
        <Box sx={styles.contentWrapper}>
          <Box sx={styles.content}>
            <Heading sx={styles.title}>
              Partner with Credify
            </Heading>
            <Flex>
              <div style={{ width: '100%', margin: '0 auto', marginTop: 0, marginBottom: 50 }}>
                <ReactMarkdown rehypePlugins={[rehypeRaw]} children={MarkdownContent} />
              </div>
            </Flex>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Partnerships;

const styles = {
  contentWrapper: {
    display: [null, null, null, 'flex', 'grid'],
    gridTemplateColumns: '1',
    alignItems: 'flex-start',
    justifyContent: 'center',
    pt: [100, null, null, 120, 130, 120, 150],
    pb: [null, null, null, null, 100, 100, 100],
  },
  content: {
    maxWidth: [507, null, null, 800, 1000],
  },
  text: {
    fontSize: ['14px', '14px', '14px', '16px', '16px', '22px'],
    lineHeight: [1.85, 1.85, 1.85, 1.85, 1.85, 2.33],
    color: 'textSecondary',
    mt: ['30px', null, null, null, '42px', '40px', '60px'],
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: ['30px', null, null, null, '42px', '40px', '60px'],
    lineHeight: 1.33,
    letterSpacing: '-1px',
    color: 'textSecondary',
  },
};
