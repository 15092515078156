import { Box, Container } from 'theme-ui';
import SectionHeading from '../../components/section-heading';
import Feature from '../../components/cards/feature';

import bulb from '../../assets/images/icons/bulb.png';
import dart from '../../assets/images/icons/dart.png';
import rocket from '../../assets/images/icons/rocket.png';
import trophy from '../../assets/images/icons/trophy.png';
import award from '../../assets/images/icons/award.png';

const data = [
  {
    id: 1,
    icon: bulb,
    title: 'Wide Choice',
    description: 'Choose from a fast growing number of mortgage applications in your state.',
  },
  {
    id: 2,
    icon: trophy,
    title: 'Low Cost',
    description: 'Cost per closed deal capped at 50bps.',
  },
  {
    id: 3,
    icon: dart,
    title: 'Full Docs',
    description: 'All documents are already included saving enormous amounts of time.',
  },
  {
    id: 4,
    icon: rocket,
    title: 'Automation Suite',
    description: 'Close deals while you sleep with our bidding automation suite.',
  },
  {
    id: 5,
    icon: award,
    title: 'Upsell Insurance',
    description: 'Without paying Credify anything.',
  },
];

const Features = () => {
  return (
    <Box as="section" id="features" variant="section.features">
      <Container>
        <SectionHeading
          title="Credify is the best way to win new business."
          description="You'll never pay for leads again."
        />
        <Box sx={styles.features}>
          {data?.map((item) => (
            <Feature key={item.id} data={item} />
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Features;

const styles = {
  heading: {
    marginBottom: [60, 60, 60, 80],
  },
  features: {
    gap: ['35px 60px', 60, 60, 40, 30, 60],
    display: ['grid', 'grid'],
    gridTemplateColumns: [
      'repeat(1, 1fr)',
      'repeat(1, 1fr)',
      'repeat(1, 1fr)',
      'repeat(3, 1fr)',
      'repeat(5, 1fr)',
    ],
  },
};
