import React from 'react';
import { ThemeProvider } from 'theme-ui';
import theme from '../theme';
import Layout from '../components/layout';
import CredifyTermsOfService from '../sections/terms-of-service/page';

const CredifyTermsOfServicePage = () => {
    return (
        <ThemeProvider theme={theme}>
            <Layout>
                <CredifyTermsOfService />
            </Layout>
        </ThemeProvider>
    )
}

export default CredifyTermsOfServicePage;
