import { ThemeProvider } from 'theme-ui';
import theme from '../theme';
import Layout from '../components/layout';
import HowItWorksBanner from '../sections/howcredifyworks/banner';
import HowItWorksFeatures from '../sections/howcredifyworks/features';
import HowItWorksFaq from '../sections/howcredifyworks/faq';

const HowCredifyWorksPage = () => {
    return (
        <ThemeProvider theme={theme}>
            <Layout>
                <HowItWorksBanner />
                <HowItWorksFeatures />
                {/* <BorrowerPricing /> */}
                <HowItWorksFaq />
            </Layout>
        </ThemeProvider>
    )
}

export default HowCredifyWorksPage;
