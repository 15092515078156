import { Link as A } from 'theme-ui';
import { HiOutlineChevronRight } from 'react-icons/hi';

export function NavLink({ path, label, children, ...rest }) {
  return (
    <Link
      href={path}
      className="nav-item"
      activeClass="active"
      {...rest}
    >
      {label}
    </Link>
  );
}
export function Link({ path, label, children, ...rest }) {
  return (
    <a style={{ textDecoration: 'none' }} href={path}>
      <A {...rest}>{children ? children : label}</A>
    </a>
  );
}

export function LearnMore({ path, label, children, ...rest }) {
  return (
    <a style={{ textDecoration: 'none' }} href={path}>
      <A sx={styles.learnMore} {...rest}>
        {label ?? 'Learn More'} <HiOutlineChevronRight />
      </A>
    </a>
  );
}

const styles = {
  learnMore: {
    color: 'link',
    cursor: 'pointer',
    fontWeight: 500,
    display: 'inline-flex',
    alignItems: 'center',
    svg: {
      transition: 'margin-left 0.3s ease-in-out 0s',
      ml: '3px',
    },
    ':hover': {
      svg: {
        ml: '8px',
      },
    },
  },
};
