import MUIDataTable from "mui-datatables";
import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

const muiCache = createCache({
  key: "mui",
  prepend: true
});

function Table() {
  const theme = createTheme({})

  const columns = [
    {
      name: "Benefit",
      label: "Benefit",
      options: {
        setCellProps: value => { return { style: { textAlign: 'left' } } },
        setCellHeaderProps: value => { return { style: { minWidth: '260px' } } },
      },
    },
    {
      name: "Credify",
      label: "Credify",
      options: {
        setCellProps: value => { return { style: { textAlign: 'center' } } },
      },
    },
    {
      name: "LendingTree",
      label: "LendingTree",
      options: {
        setCellProps: value => { return { style: { textAlign: 'center' } } },
      },
    },
    {
      name: "Brokers",
      label: "Brokers",
      options: {
        setCellProps: value => { return { style: { textAlign: 'center' } } },
      },
    },
    {
      name: "Banks",
      label: "Banks",
      options: {
        setCellProps: value => { return { style: { textAlign: 'center' } } },
      },
    }
  ];

  const options = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    sort: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    rowsPerPage: 100,
    pagination: false,
    responsive: 'standard',
    tableBodyHeight: '70vh',
    tableBodyMaxHeight: '710px',
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    }
  };

  const data = [
    ["The first true US mortgage marketplace?", "✅", "❌", "❌", "❌"],
    ["Complete a full mortgage application in your coffee break?", "✅", "❌", "❌", "❌"],
    ["Enables mortgage group buying to minimize monthly payments?", "✅", "❌", "❌", "❌"],
    ["Your mortgage is automatically grouped with similar applicants to create a group buy?", "✅", "❌", "❌", "❌"],
    ["Lenders automatically process thousands of simultaneous mortgages for huge savings?", "✅", "❌", "❌", "❌"],
    ["Any licensed lender or broker can bid to supply your mortgage, surfacing the lowest monthly payments for you automatically?", "✅", "❌", "❌", "❌"],
    ["Choose the offer you like best with one click to connect to the broker or lender?", "✅", "❌", "❌", "❌"],
    ["Lenders receive a full application with all your supporting documents automatically?", "✅", "❌", "❌", "❌"],
    ["Soft credit score protects your credit rating", "✅", "✅", "✅", "✅"],
    ["No unwanted phone calls and emails", "✅", "❌", "✅", "✅"],
    ["Pre-qualify for mortgages automatically", "✅", "❌", "❌", "❌"],
    ["No meetings or phone calls needed to find your best rate", "✅", "❌", "❌", "❌"],
    ["Expert phone support to help you complete your application or even do it for you", "✅", "❌", "✅", "✅"],
    ["Complete lender market coverage", "✅", "❌", "🤔", "❌"],
    ["Exclusive mortgage broker rates", "✅", "❌", "🤔", "❌"],
    ["Completely secure data collection which you control", "✅", "❌", "✅", "✅"],
    ["Eliminates unconscious bias in lending decisions", "✅", "❌", "❌", "❌"],
    ["Fast, automated application processing", "✅", "❌", "❌", "❌"],
    ["Eliminates the need to complete another application if a lender declines you", "✅", "❌", "❌", "❌"],
    ["Ends rate shopping forever because the best deals automatically come to you", "✅", "❌", "🤔", "❌"],
    ["One stop service covering conventional, VA, FHA, jumbo, rentals, second homes and other loan types", "✅", "❌", "🤔", "❌"],
    ["Covers all qualifying FICO scores?", "✅", "❌", "🤔", "❌"],
    ["Available for home buyers, refinances, HELOCs and second homes?", "✅", "✅", "✅", "✅"]
  ]

  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={theme}>
        <MUIDataTable
          data={data}
          columns={columns}
          options={options}
        />
      </ThemeProvider>
    </CacheProvider>
  );
}

export default Table