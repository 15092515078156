import { Component } from 'react';
import axios from 'axios';
import './App.css';
import HomePage from './pages/index'
import BrokersPage from './pages/brokers';
import BorrowersPage from './pages/borrowers';
import DisclosuresPage from './pages/disclosures-licensing';
import PrivacyPolicyPage from './pages/privacypolicy';
import WebsiteTermsOfUsePage from './pages/termsofuse';
// import AboutUsPage from './pages/aboutus';
import CredifyTermsOfServicePage from './pages/termsofservice';
import HowCredifyWorksPage from './pages/howcredifyworks';
import CalculatorsPage from './pages/calculators';
import RateGuaranteePage from './pages/rate-guarantee';
import PartnershipsPage from './pages/partnerships';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import * as Sentry from "@sentry/react";

axios.defaults.baseURL =
  process.env.REACT_APP_NODE_ENV === 'production'
    ? 'https://api.getcredify.com/v1'
    : process.env.REACT_APP_NODE_ENV === 'staging'
      ? 'https://api.staging.getcredify.com/v1'
      : 'http://localhost:8000/v1';

export default class App extends Component {
  componentDidMount() {
    this.props.hideLoader();
  }
  render() {
    return (
      <Sentry.ErrorBoundary>
        <div className='App'>
          <Router>
            <Switch>
              {/* Defined page routes */}
              <Route exact path="/">
                <BorrowersPage />
              </Route>
              <Route exact path="/brokers">
                <BrokersPage />
              </Route>
              <Route exact path="/borrowers">
                <BorrowersPage />
              </Route>
              <Route exact path="/how-credify-works">
                <HowCredifyWorksPage />
              </Route>
              <Route exact path="/disclosures-licensing">
                <DisclosuresPage />
              </Route>
              {/* <Route exact path="/about-us">
                <AboutUsPage />
              </Route> */}
              <Route exact path="/privacy-policy">
                <PrivacyPolicyPage />
              </Route>
              <Route exact path="/terms-of-use">
                <WebsiteTermsOfUsePage />
              </Route>
              <Route exact path="/terms-of-service">
                <CredifyTermsOfServicePage />
              </Route>
              <Route path="/calculators">
                <CalculatorsPage />
              </Route>
              <Route path="/rate-guarantee">
                <RateGuaranteePage />
              </Route>     
              <Route path="/partnerships">
                <PartnershipsPage />
              </Route> 
              <Route path="/home">
                <HomePage />
              </Route>                       
              <Route path="*">
                <Redirect to="/home" />
              </Route>
            </Switch>
          </Router>
        </div >
      </Sentry.ErrorBoundary>
    );
  }
};
