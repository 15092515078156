import { ThemeProvider } from 'theme-ui';
import theme from '../theme';
import Layout from '../components/layout';
import BorrowerBanner from '../sections/borrowers-2/banner';
// import BorrowerLanderForm from '../sections/borrowers-2/lander-form';
import BorrowerHowItWorks from '../sections/borrowers-2/how-it-works';
import BorrowerFaq from '../sections/borrowers-2/faq';
import BorrowerComparisonTable from '../sections/borrowers-2/comparison-table';
// import BorrowerWhyPreapprovalMatters from '../sections/borrowers-2/why-preapproval-matters';
import BorrowerTheWayYouWant from '../sections/borrowers-2/the-way-you-want';
import BorrowerAboutCredify from '../sections/borrowers-2/what-is-credify';

const Borrowers = () => {
    return (
        <ThemeProvider theme={theme}>
            <Layout>
                <BorrowerBanner />
                {/* <BorrowerLanderForm box="alternatingBox" /> */}
                <BorrowerHowItWorks box="alternatingBox" />
                <BorrowerTheWayYouWant />
                <BorrowerComparisonTable box="alternatingBox" />
                <BorrowerAboutCredify />
                <BorrowerFaq />
            </Layout>
        </ThemeProvider>
    )
}

export default Borrowers;
