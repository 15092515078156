const MarkdownContent =
    `
Credify offers a best rate guarantee to all borrowers, subject to our Terms & Conditions outlined below and in addition to our general Terms of Service available [here](https://getcredify.com/terms-of-service).

If, within 60 days of completing a mortgage through a broker or lender on Credify you find a better rate elsewhere, we will pay you double the difference in monthly repayments, every month, for 3 years!

This offer applies to all customers who complete a mortgage through a broker or lender on Credify.

The lower rate must be a like-for-like comparison of loan amount, rate type, closing costs, points, loan to value, affordability score, rate duration and loan applicants.

This guarantee does not extend to these situations:
- instances where there has been a material change in the applicants credit score
- wholesale mortgage rates
- instances where national or international monetary policies, such as interest rates, change and result in widespread market changes. 

In other words, you cannot exercise Credify's rate guarantee if, at some point in the future, rates that influence the wider market change and as a consequence, better offers become available. Such variation in the market is expected over time and is outside the bounds of this offer.

Additionally, the lower mortgage rate must have been available to the general public at the time you submitted your application to Credify. 

Rebates are limited to the first $500k of any mortgage. For mortgages of over $500k, the rate guarantee will only cover the difference in repayments calculated for the equivalent offer at $500k.

This guarantee does not constitute an offer of credit nor is it a guarantee that you will receive an offer of a mortgage loan on Credify.

This rebate offer does not constitute a money transmission.
`

export default MarkdownContent
