import React from 'react';
import { ThemeProvider } from 'theme-ui';
import theme from '../theme';
import Layout from '../components/layout';
import HomeBanner from '../sections/home/banner';

const HomePage = () => {
    return (
        <ThemeProvider theme={theme}>
            <Layout>
                <HomeBanner />
            </Layout>
        </ThemeProvider>
    )
}

export default HomePage;
