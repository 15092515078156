const MarkdownContent = `We are excited to announce that Credify is now open to Marketing Service Agreements (MSA) from a wide variety of qualifying partners through an embedded, branded mortgage portal for each qualifying partner.

Credify is a state of the art mortgage origination and automation marketplace platform. Our mission is to drive down the time and cost of sourcing and closing a mortgage for all mortgage borrowers.

Our unique mortgage auction process enables borrowers to receive mortgage offers from a wide range of mortgage brokers and lenders in a transparent auction, quickly and easily. Each borrower then chooses the mortgage offer they like best, backed by our unique best rate guarantee.

Credify's application processing API for brokers and lenders then reduces closing time and effort, further reducing costs, as expressed in the mortgage offers surfaced in the auction process.

The end result for borrowers is that they get the best mortgage rate and closing costs for their unique situation.

# Requirements for MSA Partners

- Operate in a qualifying charitable or business vertical. We do not accept applications from organizations engaged in the promotion of alcohol, tobacco, cannabis, firearms, adult or pornograpic material, gambling or political activism. We reserve the right to refuse other applicants on a case by case basis
- Conduct marketing activities on behalf of the partnership, using only pre-approved marketing materials
- If a partner has nationwide reach, be able to segment the audience on a state-by-state basis to ensure state level legal compliance, which varies from state to state
- To partner with Credify, you must agree to our terms and conditions

# Types of Partnerships Considered, subject to state level legal compliance

- Revenue sharing, provided it is firmly anchored in commercial reality with respect to the market value of the marketing services by using factors such as the cost of alternative acquisition channels, time, difficulty, or the size of the target audience.
- Advertizing
- Inclusion in rewards programs
- Member rebates for completed mortgages (not available in all states)
- Sponsorship of events at market rates
- A combination of the above

# Limitations

- Real Estate Settlement Procedures Act (RESPA) regulations require that a MSA partner MUST do demonstrable "work" in order to receive fair compensation. We are unable to enter into partnerships with organizations who do no demonstrable "work".
- For more information and to see if your organization qualifies, please contact us and someone will reply to you within 3 business days.
    
<div align="center"><strong>Interested in becoming a partner?</strong></div>

<div align="center">Get in touch with us at <a href="mailto:hello@getcredify.com">hello@getcredify.com</a></div>
    
`

export default MarkdownContent
