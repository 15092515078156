import React from 'react';
import { ThemeProvider } from 'theme-ui';
import theme from '../theme';
import Layout from '../components/layout';
import PrivacyPolicy from '../sections/privacy-policy/page';

const PrivacyPolicyPage = () => {
    return (
        <ThemeProvider theme={theme}>
            <Layout>
                <PrivacyPolicy />
            </Layout>
        </ThemeProvider>
    )
}

export default PrivacyPolicyPage;
