import { Container, Box } from 'theme-ui';
import SectionHeader from '../../components/section-heading';
import Accordion from '../../components/accordion/accordion';
const faqs = [
  {
    title: 'What sort of home loans and credit scores are needed to qualify for a mortgage on Credify?',
    contents: (
      <div>
        You can get a home loan for almost any purpose. Our market of brokers and lenders can service credit scores above 550. All loan types are available – from first-time buyers to refinancing and rental properties.
      </div>
    ),
  },
  {
    title: 'How can you offer better than best market rates?',
    contents: (
      <div>
        <span>Credify’s systems make it easy for lenders to see borrower applications. Lenders only see the relevant info needed to make a mortgage offer. So, you won't get annoying phone calls and emails like with other online brokers!</span><p>Lenders compete to secure your business by offering attractive interest rates. Our automation systems make this practical for them – that's why they can afford to do so. All lenders are competing for your business, so you don't need to waste time shopping around. Likewise, lenders spend less time and money on finding new customers. It's win-win.</p>
      </div>
    ),
  },
  {
    title: 'How does the process work?',
    contents: (
      <div>
        <span>When you place your mortgage requirements on Credify, we group your mortgage with similar mortgages. Our algorithms group mortgages based on risk factors, which is the largest factor in determining the mortgage interest rate.</span><p>When your mortgage application is published, brokers and lenders can offer you an interest rate for your individual mortgage.</p><p>If there are a lot of other borrowers like you, the size of your “group” becomes appealing to lenders to offer one rate to all borrowers in your group. They do this to secure hundreds or thousands of mortgages at once.</p><p>This group rate will beat any rate offered by lenders to an individual borrower since they save an enormous amount of money in both finding the loans and processing them.</p><p>Lenders use our API to automate many parts of the processing of all the loans. This drives down the processing cost of the mortgage.</p><p>In addition, lenders pay a fraction of the rate they would pay a broker to Credify. Lenders enjoy other savings which are expressed to borrowers through reduced mortgage interest rates, which lowers your monthly payments.</p><p>In the end you win because the lenders compete to offer you the best rate in a transparent marketplace where YOU have the control.</p>
      </div>
    ),
  },
  {
    title: 'What is the best way to get a better than best market rate?',
    contents: (
      <div>
        <span>Great question! Complete your Credify application well in advance of when you need a mortgage. If you have a refinance opportunity in less than 12 months, create your application today. The sooner you and others like you do this, the better the rate you will enjoy.</span><p>This is because lenders will see bulk financing opportunities in advance. Much like buying wholesale products in bulk, lenders  want to fulfill a lot of mortgages at once. It's group-buying that benefits both sides.</p>
      </div>
    ),
  },
  {
    title: 'How many offers will I get?',
    contents: (
      <div>
        You will get as many offers as it takes until no other broker or lender can beat the best rate. It's up to you to wait as long as you want before you choose an offer, or your application end date arrives.
      </div>
    ),
  },
  {
    title: 'What data do I need to provide?',
    contents: (
      <div>
        Mortgage applications need a lot of information. We use smart integrations to pull a lot of this from public sources and private ones that you connect. For financial data we use Plaid, which is a secure service used by many major US banks. With this automation, we save you a lot of time and hassle by digitizing the application process.
      </div>
    ),
  },
  {
    title: 'I don’t know the type of mortgage that suits my circumstances. Do I get advice with Credify?',
    contents: (
      <div>
        If you need advice, you can usually get it from any lenders loan officer for free. Once you've done that, you can confidently get the best rate at Credify to suit your wishes.
      </div>
    ),
  },
  {
    title: 'What home loan verticals do you cover?',
    contents: (
      <div>
        Our brokers and lenders cover all home loan verticals. FHA, Conventional, VA, HUD, Jumbo and rental property loans. Fixed and teaser rates, for home loan durations of 5–30 years.
      </div>
    ),
  },
  {
    title: 'I have a hard to place mortgage requirement. Can Credify help me?',
    contents: (
      <div>
        Yes! Credify has lots of specialist brokers and lenders who specialise in small niches of the mortgage market.
      </div>
    ),
  },
  {
    title: 'Is there an obligation to accept a rate on Credify?',
    contents: (
      <div>
        There is no obligation for you to accept any offer on Credify.
      </div>
    ),
  },
  {
    title: 'How does the offer process work?',
    contents: (
      <div>
        <span>Let's say broker #1 makes an offer at zero upfront fees and 2.5% interest rate, fixed for 3 years. If no other broker or lender can beat this rate, they can't make an offer.</span><p>Then broker #2 comes in with a special offer at 2.3%, fixed for 3 years with no upfront fees. Naturally, you’d choose the second offer, all else being equal.</p><p>This is like an auction, so brokers and lenders surface the best deal for your situation automatically.</p><p>When you are happy with the rates, you choose to accept the offer with a few simple clicks. You get the lender contact details and your full application details go to the lender. This includes your contact details and credit data, allowing the lender to finalize the application. The lender will connect with you directly from this point onward.</p>
      </div>
    ),
  },
  {
    title: 'Why do I have to provide my full documentation to submit an application?',
    contents: (
      <div>
        <span>It's in your best interest to do so, because you will get higher quality offers. And in the rare event that a lender declines to finalize an application, you won't have to start from scratch.</span><p>Providing your full documentation also makes processing very fast, as lenders are not missing any relevant data. If you leave out important details, it makes it harder for them to pre-approve underwriting.</p><p>Remember that we don't send everything to lenders when you submit an application. They can only see the minimum data they need to determine if they could underwrite, and at what rate. Your data is only passed to the lender you choose.</p>
      </div>
    ),
  }
];
export default function Faq() {
  return (
    <Box as="section" id="faq" variant="section.faq">
      <Container>
        <SectionHeader
          title="Do you have any questions?"
          description="Contact us and receive a response in 24 hour or less."
        />
        <Box
          sx={{
            display: 'flex',
            width: ['100%', null, null, '650px', '745px'],
            flexDirection: 'column',
            mx: 'auto',
            my: -4,
          }}
        >
          <Accordion items={faqs} />
        </Box>
      </Container>
    </Box>
  );
}
