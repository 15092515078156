export const menuItems = [
  // {
  //   id: 1,
  //   title: 'Company',
  //   items: [
  //     {
  //       path: '/about-us',
  //       label: 'Company Info',
  //     },
  //     {
  //       path: '/the-team',
  //       label: 'The Team',
  //     },
  //   ],
  // },
  {
    id: 2,
    title: 'About Us',
    items: [
      {
        path: '/how-credify-works',
        label: 'How Credify Works',
      },
      {
        path: '/partnerships',
        label: 'Partnerships',
      },
      {
        label: 'Get Support',
        onClick: () => {
          if (!window.gist) return;
          window.gist.chat('open')
        }
      },
    ],
  },
  {
    id: 3,
    title: 'Our Information',
    items: [
      {
        path: '/disclosures-licensing',
        label: 'Disclosures and Licensing',
      },
      {
        path: '/terms-of-service',
        label: 'Terms of Service',
      },
    ],
  },
  {
    id: 4,
    title: 'My Account',
    items: [
      {
        path: `https://app.${process.env.REACT_APP_NODE_ENV === 'staging' ? 'staging.' : ''}getcredify.com/consumer/sign-in`,
        label: 'Log in for Borrowers',
      },
      {
        path: `https://app.${process.env.REACT_APP_NODE_ENV === 'staging' ? 'staging.' : ''}getcredify.com/institution/sign-in`,
        label: 'Log in for Brokers',
      },
    ],
  },
  {
    id: 5,
    title: 'Policies',
    items: [
      {
        path: '/privacy-policy',
        label: 'Privacy Policy',
      },
      {
        path: '/terms-of-use',
        label: 'Website Terms of Use',
      },
      {
        path: '/rate-guarantee',
        label: 'Best Rate Guarantee',
      },      
    ],
  },
];


export const footerNav = [
  // {
  //   path: 'login',
  //   label: 'Login',
  // },
  // {
  //   path: 'https://docs.getcredify.com',
  //   label: 'API Docs',
  // },
  {
    // path: '#',
    label: 'Contact Us',
    onClick: () => {
      if (!window.gist) return;
      window.gist.chat('open')
    }
  },
  // {
  //   path: '#!',
  //   label: 'Supports',
  // },
  // {
  //   path: '#!',
  //   label: 'Marketing',
  // },
  // {
  //   path: '#!',
  //   label: 'FAQ',
  // },
];
