import { Box, Container, Heading, Text, Image, Link } from 'theme-ui';
import theme from '../../theme';

const Banner = () => {
    return (
        <Box id="home" as="section" variant="section.banner" sx={theme.styles.box}>
            <Container>
                <Box sx={styles.contentWrapper}>
                    <Box sx={styles.content}>
                        <Heading sx={styles.title} className='borrowerHeading'>
                            Complete your mortgage application through Credify and enjoy our unique mortgage rate guarantee!
                        </Heading>
                        <Text as="p" sx={styles.text} className='borrowerSubHeading'>
                            If, within 60 days of completing a mortgage through a broker or lender on Credify you find a better rate elsewhere, we’ll pay you DOUBLE THE DIFFERENCE every month for 3 years!
                        </Text>
                        {/* <Text as="p" sx={styles.text} className='borrowerSubHeading'>
                            Available to all borrowers with FICO scores above 550 and for all home loan types.
                        </Text> */}
                        <Link className='borrowerCta' data-lum="action" variant="primary" sx={styles.button} href={`https://approve${process.env.REACT_APP_NODE_ENV === 'staging' ? '-staging4nf' : ''}.getcredify.com`}>
                            Get Turbo Approved Now
                        </Link>
                        <Link className='borrowerCta callingCta' data-lum="action" variant="primary" sx={styles.callButton} href='tel:+18005576196'>
                            Call Us Instead
                        </Link>
                    </Box>
                    <Box sx={styles.illustration}>
                        <Image alt="banner" className="borrowerBanner bannerSrc0" />
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default Banner;

const styles = {
    contentWrapper: {
        display: [null, null, null, 'flex', 'grid'],
        gridTemplateColumns: 'repeat(2, 1fr)',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: [null, null, null, null, null, '100vh'],
        pt: [100, null, null, 120, 130, 120, 0],
        pb: [50, null, null, 120, 130, 120, 0],

    },
    content: {
        maxWidth: [507, null, null, 324, 450],
    },
    title: {
        fontWeight: 'bold',
        fontSize: ['30px', null, null, '32px', '38px', '38px'],
        lineHeight: 1.33,
        letterSpacing: '-1px',
        color: 'textSecondary',
    },
    text: {
        fontSize: ['14px', '14px', '14px', '16px', '16px', '18px'],
        lineHeight: [1.3, 1.4, 1.4, 1.4, 1.5, 1.8],
        color: 'textSecondary',
        mt: ['14px', '19px'],
    },
    button: {
        display: ['flex', 'flex'],
        justifyContent: "center",
        width: ['100%'],
        mt: [25, 25, 25, 25, 25],
        borderRadius: 5,
        color: "#FFF",
        backgroundColor: "primary",
        lineHeight: '50px',
        height: '50px',
        fontSize: ['15px', '20px', '20px', '15px', '18px', '20px'],
        fontWeight: '500',
        textDecoration: "none",
        ":hover": {
            backgroundColor: "dark"
        }
    },
    callButton: {
        display: ['flex', 'flex', 'flex', 'flex', 'flex', 'none', 'none'],
        justifyContent: "center",
        width: ['100%'],
        mt: 10,
        borderRadius: 5,
        color: "#FFF",
        backgroundColor: "primary",
        lineHeight: '50px',
        height: '50px',
        fontSize: ['15px', '20px', '20px', '15px', '18px', '20px'],
        fontWeight: '500',
        textDecoration: "none",
        pr: 15,
        ":hover": {
            backgroundColor: "dark"
        }
    },
    clients: {
        display: 'flex',
        alignItems: 'center',
        mt: ['20px', '45px', '45px', '30px', '45px'],
        img: {
            maxWidth: ['80px', '100%', '100%', '100%'],
            '+ img': {
                ml: ['14px', '28px', '28px', '20px'],
            },
        },
    },
    illustration: {
        display: ['block', 'block'],
        mt: ['30px', '30px', '30px', 0],
        mb: ['50px', '0px', 0],
        ml: ['0px', '0px', '0px', '30px', '20px', 0],
        img: {
            maxWidth: ['100%'],
        },
    }
};
